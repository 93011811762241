/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { isEmpty as _isEmpty } from 'lodash';
import { useParams, useHistory } from 'react-router-dom';

// @material-ui
import { Box, Paper, Typography } from '../../../components/shared/MaterialUI';

// @state
import { QuestionnaireContext } from '../Prescreening';
import { useUI } from '../../../app/context/ui';

// @assets
import AppHelper from '../../../helpers/AppHelper';
import { ThankyouStyles } from '../../../assets/css/questionnaire-style';

// @services
import QuestStartServiceNewApi from '../../../services/newApi/QuestionnaireServiceNewApi';

// @components
import DriverLicense from './DriverLicense';

const ThankYou = () => {
  const {
    initial,
    t,
    companyLogo,
    lang,
    updateQuestion
  } = useContext(QuestionnaireContext);
  const { messages, confirmationCode } = initial;
  const questService = useMemo(() => new QuestStartServiceNewApi(), []);
  const style = useMemo(() => ThankyouStyles(),[]);
  const { hashToken } = useParams();
  const { blockUI, snackbarUI } = useUI();
  const firstRender = useRef(true);
  const history = useHistory();

  const callWebhook = async () => {
    try {
      blockUI.current.open(true);
      await questService.callWebhook(hashToken)
      blockUI.current.open(false);
    } catch (error) {
      blockUI.current.open(false);
      AppHelper.checkError(error, snackbarUI);
    }
  }
  
  useEffect(() => {
    if (firstRender.current) {
      callWebhook()
      firstRender.current = false;
    }
    if(!initial?.hasDriverLicenseFile && (initial?.isEZ || initial?.isRRC))return;
    if(initial?.isVeteran && !initial?.hasDD214Answer){
      setTimeout(() => {
        if(lang)return history.push(`/upload-file-dd214/${hashToken}?lang=${lang}`)
        return history.push(`/upload-file-dd214/${hashToken}`)
      }, 2000);
    }
  }, [initial])

  return (
    <>
      <Box sx={{...style.containerThanks, top: companyLogo ? '150px' : "60px" }}>
        {!initial?.hasDriverLicenseFile && (initial?.isEZ || initial?.isRRC)
          ? <DriverLicense
              lang={lang}
              hashToken={hashToken}
              updateQuestion={updateQuestion}
              initial={initial}
            />
          : <Paper elevation={0} sx={style.paperThanks}>
              <Box sx={style.containerText}>
                <Typography variant='h1' sx={style.textGreat}>
                  {t("question.great")}
                </Typography>

                {!_isEmpty(messages)
                  ? ( <>
                        <Typography variant="body1" sx={style.textStatus}>
                          {messages.statusMessage}.
                        </Typography>
                      </>)
                  : ('')
                }
                <>
                  <Typography variant="body1" sx={style.textStatus}>
                    {t("question.codeConfirmation")} {confirmationCode}.
                  </Typography>

                  <Typography variant="body1" sx={style.textStatus}>
                      {t("question.closeWindow")}
                  </Typography>
                </>
              </Box>
            </Paper>
        }
      </Box>
      <Box sx={style.emptyContainer} />
    </>

  
  );
};

export default ThankYou;
