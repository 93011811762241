// @vendors
import React, { useContext, useMemo, useState } from 'react';
//import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';

// @material-ui
import {
  Paper,
  Box,
  DataGrid,
} from "../../../components/shared/MaterialUI";
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
/* import {
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'; */

// @assets
import { PayrollStyle } from '../../../assets/css/payroll-style';
import editIcon from '../../../assets/images/edit.svg';
import CustomNoRowsOverlay from '../../../assets/CustomNoRowsOverlay';

// @components
import DialogNotes from '../Edit/DialogNotes';
import EditItemPayroll from '../Edit/EditItemPayroll';
import InvoiceSumary from './InvoiceSumary';
import PaginatorTable from './components/PaginatorTable';
import ToolbarPayroll from './components/ToolbarPayroll';
import FilterCompany from './components/FilterCompany';
import { CustomColumnsPanel } from '../../Admin/components/custom/CustomPreferences';
import YTDModal from './components/YTDModal';
//import PayrollRow from './components/PayrollRow';
//import HiddeColumn from './components/HiddeColumn';

// @context
import { payrollContext } from '../payrollView';

// @helpers
import {
  convertNameStateStatus,
  formatCurrency,
  formattDate,
  getTargetGroup,
  getTargetGroupTooltip,
  HtmlTooltip,
  validateEmptyString,
} from '../../../helpers/FunctionsUtils';
import AppHelper from '../../../helpers/AppHelper';

// @services
import PayrollService from '../../../services/newApi/PayrollService';
import { useUI } from '../../../app/context/ui';

const PayrollTable = () => {
  const style = useMemo(() => PayrollStyle(), []);
  const [anchorEl, setAnchorEl] = useState(null);
  const { blockUI, snackbarUI } = useUI();
  const payrollService = useMemo(() => new PayrollService(), []);
  const [itemSelected, setItemSelected] = useState(null);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const { currentTab, payrollList } = useContext(payrollContext);
  //const [openHiddeColumn, setOpenHiddeColumn] = useState(false);
  const [open, setOpen] = useState(false);
  const [dataYtd, setDataYtd] = useState([]);
  /* const [isColumnHidden, setIsColumnHidden] = useState({
    Last_Sync_Date: true,
    State_Status: true,
    Employee_Full_Name: true,
    Employeer: true,
    Employer_Rate: true,
    Target_Group: true,
    Target_Group_Code: true,
    Start_Date: true,
    Pay_Schedule: true,
    Credit: true,
    Current_YTD_Gross_wages: true,
    Current_YTD_Hours: true,
    WOTC_Credit_Multiplier: true,
    Max_Wage: true,
    Max_Tax_Credit: true,
    Tax_Credit_YTD: true,
    Invoice_YTD: true,
    Actual_Invoice: true,
  }) */

  //const handleColumn = () => setOpenHiddeColumn((prev) => !prev);

  const handleClickNotes = (event, item) => {
    setItemSelected(item)
    setAnchorEl(event.currentTarget);
  };

  const handleCloseModal = () => {
    setAnchorEl(null);
    setItemSelected(null)
    setOpenModalEdit(false)
  };

  const handleOpenModalEdit = (item) => {
    setItemSelected(item);
    setOpenModalEdit(true);
  }
  
  /* const applyStyle = (value) => {
    if(value)return style.tableHeadTitle;
    return style.tableRowHidde;
  } */

  const employerName = (name) => {
    if(name?.length > 40)return `${name?.substring(0, 40)}...`;
    return name;
  }

  const getYtdValue = async (employeeId) => {
    try {
        blockUI.current.open(true);
        const response = await payrollService.getYtdByEmployees(employeeId);
        setDataYtd(response?.data?.data);
        blockUI.current.open(false);
    } catch (error) {
        blockUI.current.open(false);
        AppHelper.checkError(error, snackbarUI);
    }
  } 

  const handleOpenModal = (employeeId) => {
    getYtdValue(employeeId);
    setOpen(true);
  }

  const handleCloseModalYtd = () => setOpen(false);

  const wotcCreditMultiplier = (value) => {
    if(value !== null & value !== undefined)return `${value}%`;
    return 
  }

  const columns = [
    {
      field: "actionButtons",
      headerName: "Actions",
      filterable: false,
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
            gap: '15px',
            paddingLeft: '20px',
            alignItems: 'center',
          }}
        >
          <SpeakerNotesIcon
            onClick={(e) => handleClickNotes(e, params?.row)}
            sx={style.iconMessageAndEdit}
          />
          <img
            src={editIcon}
            alt='Buttom for edit'
            style={style.iconMessageAndEdit}
            onClick={() => handleOpenModalEdit(params?.row)}
          />
        </Box>
      ),
    },
    {
      field: "lastSyncDate",
      headerName: "Last Sync Date",
      sortable: false,
      filterable: false,
      width: 140,
      renderCell: (params) => (
        <Box sx={{paddingLeft: '25px'}}>
          {formattDate(params?.row?.lastSyncDate)}
        </Box>
      ),
    },
    {
      field: "stateStatus",
      headerName: "State Status",
      sortable: false,
      filterable: false,
      width: 115,
      renderCell: (params) => (
        <Box sx={{paddingLeft: '25px'}}>
          {convertNameStateStatus(params?.row?.stateStatus)}
        </Box>
      ),
    },
    {
      field: "employeeFullName",
      headerName: "Employee Full Name",
      sortable: false,
      filterable: false,
      width: 200,
      renderCell: (params) => (
        <Box sx={{paddingLeft: '25px'}}>
          {params?.row?.employeeFullName}
        </Box>
      ),
    },
    {
      field: "employeer",
      headerName: "Employeer",
      sortable: false,
      filterable: false,
      width: 200,
      renderCell: (params) => (
        <Box sx={{paddingLeft: '25px'}}>
          {employerName(params?.row?.employeer)}
        </Box>
      ),
    },
    {
      field: "employeerRate",
      headerName: "Employer Rate (%)",
      sortable: false,
      filterable: false,
      width: 120,
      renderCell: (params) => (
        <Box sx={{paddingLeft: '25px'}}>
          {params?.row?.employeerRate}
        </Box>
      ),
    },
    {
      field: "targetGroups",
      headerName: "Target Group",
      sortable: false,
      filterable: false,
      width: 180,
      renderCell: (params) => (
        <Box sx={{paddingLeft: '25px'}}>
          <HtmlTooltip title={getTargetGroupTooltip(params?.row?.targetGroups) ?? ''} placement="right-start">
            {getTargetGroup(params?.row?.targetGroups, false)}
          </HtmlTooltip>
        </Box>
      ),
    },
    {
      field: "payScheduleId",
      headerName: "Target Group Code",
      sortable: false,
      filterable: false,
      width: 180,
      renderCell: (params) => (
        <Box sx={{paddingLeft: '25px'}}>
          {getTargetGroup(params?.row?.targetGroups, true)}
        </Box>
      ),
    },
    {
      field: "startDate",
      headerName: "Start Date",
      sortable: false,
      filterable: false,
      width: 115,
      renderCell: (params) => (
        <Box sx={{paddingLeft: '25px'}}>
          {formattDate(params?.row?.startDate)}
        </Box>
      ),
    },
    {
      field: "paySchedule",
      headerName: "Pay Schedule",
      sortable: false,
      filterable: false,
      width: 130,
      renderCell: (params) => (
        <Box sx={{paddingLeft: '25px'}}>
          {validateEmptyString(params?.row?.paySchedule)}
        </Box>
      ),
    },
    {
      field: "maxedOutReason",
      headerName: "Credit",
      sortable: false,
      filterable: false,
      width: 80,
      hide: currentTab === 2 ? false : true,
      renderCell: (params) => (
        <Box sx={{paddingLeft: '25px'}}>
          {params?.row?.maxedOutReason}
        </Box>
      ),
    },
    {
      field: "currentYTDGrossWages",
      headerName: "Current YTD Gross wages ($)",
      sortable: false,
      filterable: false,
      width: 150,
      renderCell: (params) => (
        <Box sx={{paddingLeft: '25px', cursor: 'pointer'}} onClick={() => handleOpenModal(params?.row?.employeeId)}>
          {formatCurrency(params?.row?.currentYTDGrossWages)}
        </Box>
      ),
    },
    {
      field: "currentYTDHours",
      headerName: "Current YTD Hours",
      sortable: false,
      filterable: false,
      width: 130,
      renderCell: (params) => (
        <Box sx={{paddingLeft: '25px', cursor: 'pointer'}} onClick={() => handleOpenModal(params?.row?.employeeId)}>
          {params?.row?.currentYTDHours}
        </Box>
      ),
    },
    {
      field: "wotcCreditMultiplier",
      headerName: "WOTC Credit % Multiplier",
      sortable: false,
      filterable: false,
      width: 130,
      renderCell: (params) => (
        <Box sx={{paddingLeft: '25px', cursor: 'pointer'}} onClick={() => handleOpenModal(params?.row?.employeeId)}>
          {wotcCreditMultiplier(params?.row?.wotcCreditMultiplier)}
        </Box>
      ),
    },
    {
      field: "maxWage",
      headerName: "Max Wage ($)",
      sortable: false,
      filterable: false,
      width: 130,
      renderCell: (params) => (
        <Box sx={{paddingLeft: '25px'}}>
          {formatCurrency(params?.row?.maxWage)}
        </Box>
      ),
    },
    {
      field: "maxTaxCredit",
      headerName: "Max Tax Credit ($)",
      sortable: false,
      filterable: false,
      width: 130,
      renderCell: (params) => (
        <Box sx={{paddingLeft: '25px'}}>
          {formatCurrency(params?.row?.maxTaxCredit)}
        </Box>
      ),
    },
    {
      field: "taxCreditYTD",
      headerName: "Tax Credit YTD ($)",
      sortable: false,
      filterable: false,
      width: 130,
      renderCell: (params) => (
        <Box sx={{paddingLeft: '25px'}}>
          {formatCurrency(params?.row?.taxCreditYTD)}
        </Box>
      ),
    },
    {
      field: "invoiceYTD",
      headerName: "Invoice YTD ($)",
      sortable: false,
      filterable: false,
      width: 130,
      renderCell: (params) => (
        <Box sx={{paddingLeft: '25px', cursor: 'pointer'}} onClick={() => handleOpenModal(params?.row?.employeeId)}>
          {formatCurrency(params?.row?.invoiceYTD)}
        </Box>
      ),
    },
    {
      field: "actualInvoice",
      headerName: "Actual Invoice ($)",
      sortable: false,
      filterable: false,
      width: 150,
      renderCell: (params) => (
        <Box sx={{paddingLeft: '25px', cursor: 'pointer'}} onClick={() => handleOpenModal(params?.row?.employeeId)}>
          {formatCurrency(params?.row?.actualInvoice)}
        </Box>
      ),
    },
  ];
 
  return (
    <>
      <Paper elevation={4}>
        <ToolbarPayroll/>
          {
            currentTab === 1 || currentTab === 2
              ? <>
                  <FilterCompany/>
                  <DataGrid
                    {...payrollList}
                    columns={columns}
                    rows={payrollList?.rows}
                    components={{
                      NoRowsOverlay: CustomNoRowsOverlay,
                      Pagination: PaginatorTable,
                      ColumnsPanel: CustomColumnsPanel,
                    }}
                    headerHeight={80}
                    rowHeight={70}
                    paginationMode="server"
                    pagination
                    disableSelectionOnClick
                    checkboxSelection={false}
                    sx={style.tableGrid}
                  />
                </> 
              : <InvoiceSumary />
          }

        <DialogNotes
          anchorEl={anchorEl}
          handleClose={handleCloseModal}
          idItemSelected={itemSelected}
        />

        <EditItemPayroll
          open={openModalEdit}
          handleClose={handleCloseModal}
          data={itemSelected}
        />
      </Paper>

      {/* <Paper elevation={4}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            paddingRight: '15px'
          }}
        >
          <ToolbarPayroll/>
          {currentTab === 1 || currentTab === 2
            ? <Box>
                <HtmlTooltip title={'Show columns'} placement="bottom">
                  <IconButton
                    aria-label="fingerprint"
                    onClick={handleColumn}
                    sx={{
                      color: '#007F6D',
                      '&:hover':{ backgroundColor: '#ECF8F7' }
                    }}
                  >
                    <MoreVertOutlinedIcon />
                  </IconButton>
                </HtmlTooltip>
              </Box>
            : null
          }
        </Box>
        <TableContainer sx={{ maxHeight: 440 }}>
          {
            currentTab === 1 || currentTab === 2
              ? <>
                  <FilterCompany/>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow sx={style.tableRow}>
                            <TableCell sx={{...applyStyle(isColumnHidden.Last_Sync_Date), width: '140px'}}>
                              Last Sync Date
                            </TableCell>
                            <TableCell sx={{...applyStyle(isColumnHidden.State_Status), width: '115px'}}>
                              State Status
                            </TableCell>
                            <TableCell sx={applyStyle(isColumnHidden.Employee_Full_Name)}>
                              Employee Full Name
                            </TableCell>      
                            <TableCell sx={applyStyle(isColumnHidden.Employeer)}>
                              Employeer
                            </TableCell>        
                            <TableCell sx={{...applyStyle(isColumnHidden.Employer_Rate), width: '120px'}}>
                              Employer Rate (%)
                            </TableCell>        
                            <TableCell sx={applyStyle(isColumnHidden.Target_Group)}>
                              Target Group
                            </TableCell>        
                            <TableCell sx={applyStyle(isColumnHidden.Target_Group_Code)}>
                              Target Group Code
                            </TableCell>        
                            <TableCell sx={{...applyStyle(isColumnHidden.Start_Date), width: '115px'}}>
                              Start Date
                            </TableCell>   
                            <TableCell sx={{...applyStyle(isColumnHidden.Pay_Schedule), width: '130px'}}>
                              Pay Schedule
                            </TableCell>
                            {currentTab === 2 &&
                              <TableCell sx={{...applyStyle(isColumnHidden.Credit), width: '80px'}}>
                                Credit
                              </TableCell>
                            }
                            <TableCell sx={{...applyStyle(isColumnHidden.Current_YTD_Gross_wages), width: '150px'}}>
                              Current YTD Gross wages ($)
                            </TableCell>
                            <TableCell sx={{...applyStyle(isColumnHidden.Current_YTD_Hours), width: '130px'}}>
                              Current YTD Hours
                            </TableCell>
                            <TableCell sx={{...applyStyle(isColumnHidden.WOTC_Credit_Multiplier), width: '130px'}}>
                              WOTC Credit % Multiplier
                            </TableCell>  
                            <TableCell sx={{...applyStyle(isColumnHidden.Max_Wage), width: '130px'}}>
                              Max Wage ($)
                            </TableCell>
                            <TableCell sx={{...applyStyle(isColumnHidden.Max_Tax_Credit), width: '130px'}}>
                              Max Tax Credit ($)
                            </TableCell>
                            <TableCell sx={{...applyStyle(isColumnHidden.Tax_Credit_YTD), width: '130px'}}>
                              Tax Credit YTD ($)
                            </TableCell>
                            <TableCell sx={{...applyStyle(isColumnHidden.Invoice_YTD), width: '130px'}}>
                              Invoice YTD ($)
                            </TableCell>
                            <TableCell sx={{...applyStyle(isColumnHidden.Actual_Invoice), width: '130px'}}>
                              Actual Invoice ($)
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                      {payrollList?.rows?.map((item, index) => (
                          <PayrollRow
                            key={index}
                            isColumnHidden={isColumnHidden}
                            item={item}
                          />
                      ))}   
                    </TableBody>
                  </Table>
                  
                </> 
              : <InvoiceSumary />
          }

          {currentTab === 3 ? null : <PaginatorTable/>}
        </TableContainer>

        <DialogNotes
          anchorEl={anchorEl}
          handleClose={handleCloseModal}
          idItemSelected={itemSelected}
        />
        <EditItemPayroll
          open={openModalEdit}
          handleClose={handleCloseModal}
          data={itemSelected}
        />
      </Paper> */}

      {/* <HiddeColumn
        open={openHiddeColumn}
        handleClose={handleColumn}
        isColumnHidden={isColumnHidden}
        setIsColumnHidden={setIsColumnHidden}
      /> */}

      <YTDModal
        open={open}
        handleClose={handleCloseModalYtd}
        dataYtd={dataYtd}
      />
    </>
  )
}

export default PayrollTable
