// @vendors
import React, { useContext, useMemo } from 'react'
import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useSelector } from 'react-redux';

// @context
import { ManualContext } from '../Manuals'
import { useUI } from '../../../app/context/ui';

// @assets
import { EmployeeTableStyles } from '../../../assets/css/employeeTable-style';

// @components
import ToolbarManual from './ToolbarManual';
import CustomNoRows from '../../../components/CustomNoRows';

// @helpers
import AppHelper from '../../../helpers/AppHelper';

// @services
import ManualService from '../../../services/newApi/ManualService';
import { LISTROLES } from '../../../helpers/ListRoles';

const ManualTable = () => {
    const styles = useMemo(() => EmployeeTableStyles(), []);
    const {data, getListManuals} = useContext(ManualContext);
    const { blockUI, snackbarUI } = useUI();
    const manualService = useMemo(() => new ManualService(), []);
    const user = useSelector((state) => state?.user);

    const downloadFile = async (file) => {
        blockUI.current.open(true);
        const response = await fetch(file.file);
        if (!response.ok) blockUI.current.open(false);
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = `${file.name}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // clear dinamic link
        window.URL.revokeObjectURL(blobUrl);
        blockUI.current.open(false);
    };

    const deleteFile = async (file) => {
        try {
            blockUI.current.open(true);
            await manualService.deleteManual(file?.id);
            getListManuals();
        } catch (error) {
            blockUI.current.open(false);
            AppHelper.checkError(error, snackbarUI);
        }
    }

    const handleOpenPdf = (file) => {
       return window.open(file?.file, "_blank", "noopener,noreferrer");
    }

  return (
    <Paper elevation={4}>
        <ToolbarManual />
        <TableContainer sx={styles.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow sx={styles.tableRow}>
                <TableCell sx={styles.emptyTableCell} />

                <TableCell sx={{ ...styles.tableHeadTitle, textAlign: 'left' }}>
                  Name
                </TableCell>

                <TableCell sx={styles.emptyTableCell} />
              </TableRow>
            </TableHead>
            
            <TableBody>
                {data?.length
                    ? React.Children.toArray(data?.map((row) => <TableRow>
                        <TableCell scope="row" sx={styles.tableCell}/>
            
                        <TableCell
                            scope="row"
                            sx={{...styles.tableCell, textAlign: 'left'}}
                        >
                            <Box
                                sx={{
                                    display: 'inline-block',
                                    "&:hover": {textDecoration: 'underline', cursor: 'pointer'},
                                }}
                                onClick={() => handleOpenPdf(row)}
                            >
                                {row.name}
                            </Box>
                        </TableCell>    
                    
                        <TableCell
                            scope="row"
                            sx={{...styles.tableCell, textAlign: 'left'}}
                        >
                            <Box sx={{display: 'flex'}}>
                                <Tooltip title="Download file">
                                    <Button
                                        variant="contained"
                                        style={{ backgroundColor: "transparent", boxShadow: "none" }}
                                        children={
                                            <CloudDownloadOutlinedIcon
                                                style={{ color: "#101010", fontSize: "2rem" }}
                                                onClick={() => downloadFile(row)}
                                            />
                                        }
                                    />
                                </Tooltip>
                                {user?.role === LISTROLES.superAdmin &&
                                    <Tooltip title="Delete file">
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: "transparent", boxShadow: "none" }}
                                            children={
                                                <DeleteOutlinedIcon
                                                    style={{ color: "#101010", fontSize: "2rem" }}
                                                    onClick={() => deleteFile(row)}
                                                />
                                            }
                                        />
                                    </Tooltip>
                                }
                            </Box>
                        </TableCell> 
                        </TableRow>))
                    :   <TableRow>
                            <TableCell scope="row" sx={{...styles.tableCell, borderBottom: 'none'}}/>
                            <TableCell sx={{borderBottom: 'none'}}>
                                <Box
                                    sx={{
                                        width: '100% !important',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <CustomNoRows/>
                                </Box>
                            </TableCell>
                            <TableCell scope="row" sx={{...styles.tableCell, borderBottom: 'none'}}/>
                        </TableRow>
                }
            </TableBody>
          </Table>
        </TableContainer>
    </Paper>
  )
}

export default ManualTable