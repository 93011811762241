/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { Autocomplete } from '@mui/material';
import { useSelector } from 'react-redux';

// @services
import OrganizationServiceNewApi from '../../../services/newApi/OrganizationService';
import CompanyServiceNewApi from '../../../services/newApi/CompanyServiceNewApi';
import UserServiceNewApi from '../../../services/newApi/UserServiceNewApi';

// @context
import { useUI } from '../../../app/context/ui';

// @helpers
import AppHelper from '../../../helpers/AppHelper';
import { MenuItemProps, permissionsForOrganization } from '../../../helpers/FunctionsUtils';
import { LISTROLES } from '../../../helpers/ListRoles';

// @assets
import { StyleAPiClientDialog } from '../../ApiClient/StyleApiClient';
import { UserManagementStyle } from '../../../assets/css/employeeTable-style';
import { CreateEmployeeStyles } from '../../../assets/css/createEmployee-style';
import { GeneralStyles } from '../../../assets/css/general-style';

// @components material
import {
  Visibility,
  VisibilityOff,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  MenuItem,
  Select,
  FormHelperText,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '../../../components/shared/MaterialUI';

// @components
import { AppTextField } from '../../../components/forms';

// @constants
import { ROUTENAME } from '../../../navigation/RouteName';

const AddUser = (props) => {
  const { id } = props;
  const history = useHistory();
  const userService = useMemo(() => new UserServiceNewApi(),[]);
  const organizationService = useMemo(() => new OrganizationServiceNewApi(), []);
  const companyService = useMemo(() => new CompanyServiceNewApi(), []);
  const { blockUI, snackbarUI } = useUI();
  const style = useMemo(() => UserManagementStyle(), []);
  const generalStyle = useMemo(() => GeneralStyles(), []);
  const styleForm = useMemo(() => CreateEmployeeStyles(), []);
  const styles = useMemo(() => StyleAPiClientDialog(), []);
  const globalState = useSelector((state) => state);
  const organization = globalState?.organizationReducer?.organizationSelected;
  const user = globalState?.user;
  const [showPassword, setShowPassword] = useState(false);
  const [listRoles, setListRoles] = useState([]);
  const [listOrganizations, setListOrganizations] = useState([]);
  const [listCompanies, setListCompanies] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState([]);
  const [showCompany, setShowCompany] = useState(false);
  const [showOrganization, setShowOrganization] = useState(false);

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    role: '',
    organizationIds: '',
    companyIds: '',
    //hasAccessToAllOrganizatons: false,
    isPayroll: false,
    isApi: false,
    isTms: false,
  }
  const [formValues, setFormValues] = useState(initialValues)

  const validationEmployee = Yup.object({
      firstName: Yup.string('Enter your name')
        .required('Name is required')
        .nullable()
        .typeError(`Name can't be empty`),
      lastName: Yup.string('Enter your last Name')
        .required('last Name is required')
        .nullable()
        .typeError(`last Name can't be empty`),
      email: Yup.string('Enter the email')
        .email('Invalid email')
        .max(50, 'Max. 50 characters')
        .required('Email is required')
        .typeError(`Email can't be empty`),
      role: Yup.string('Enter your role')
        .nullable()
        .required('Role is required')
        .typeError(`Role can't be empty`),
      password: !id
        ? Yup.string('Enter your password')
            .min(8, 'Minimum 8 characters')
            .matches(
              /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).{8,}$/,
              'Must have at least one Uppercase, one symbol and one number'
            )
            .required('Password is required')
        : Yup.string()
            .nullable()
            .notRequired(),
      organizationIds: showOrganization === false
        ? Yup.string().nullable().notRequired()
        : Yup.string().required('Organization is required'),
      companyIds: showCompany === false
        ? Yup.string().nullable().notRequired()
        : Yup.string().nullable().required('Company is required'),
      //hasAccessToAllOrganizatons: Yup.bool().nullable().notRequired(),
      isPayroll: Yup.bool().nullable().notRequired(),
      isApi: Yup.bool().nullable().notRequired(),
      isTms: Yup.bool().nullable().notRequired(),
  });

  const getUser = async () => {
    try {
      blockUI.current?.open(true);
      const response = await userService.getUserById(id);
      preloadFields(response?.data);
    } catch (error) {
      blockUI.current?.open(false);
      AppHelper.checkError(error, snackbarUI);
    }
  }

  const preloadFields = (data) => {
    setFormValues({
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      password: data?.password,
      role: data?.role,
      organizationIds: data?.organizations?.length ? 'value' : '',
      companyIds: data?.companies?.length ? data?.companies[0].id : '',
      //hasAccessToAllOrganizatons: data?.hasAccessToAllOrganizatons,
      isPayroll: data?.isPayroll,
      isApi: data?.hasApiAccess,
      isTms: data?.hasTmsAccess,
    });
   if(data?.role !== LISTROLES.superAdmin)preloadOrganization(data);
   if(data?.companies?.length && checkRoleForCompany(data?.role))preloadCompany(data);
    setTimeout(() => {
      blockUI.current.open(false);
    }, 1000);
  }
  
  const preloadOrganization = (data) =>{
    //if(data?.hasAccessToAllOrganizatons)return;
    setSelectedOrg(data?.organizations);
    setShowOrganization(true);
  }
  
  const preloadCompany = (data) =>{
    getCompanies(data?.organizations[0]?.id);
    setShowCompany(true);
  }

  useEffect(() => {
    if(id)getUser();
  }, [id])

  const getAllRoles = async () => {
    try {
      blockUI.current?.open(true);
      const response = await userService.getRoles();
      setListRoles(response?.data);
      blockUI.current?.open(false);
    } catch (error) {
      blockUI.current?.open(false);
      AppHelper.checkError(error, snackbarUI);
    }
  }

  const disableRole = (role) => {
    const isRockerbox = organization?.name?.toLowerCase();
    if(isRockerbox !== 'rockerbox' && role === LISTROLES.orgProcessor)return true;
    return false
  }

  const showRoleName = (role) => {
    const isRockerbox = disableRole(role);
    if(isRockerbox)return 'Rockerbox processor';
    return role?.replace('_', ' ');
  }

  const getCompanies = async (organizationId) => {
    try {
      blockUI.current?.open(true);
      const response = await companyService.getCompanies({page:1, size:1000}, organizationId);
      setListCompanies(response?.data?.data)
      blockUI.current?.open(false);
    } catch (error) {
      blockUI.current?.open(false);
      AppHelper.checkError(error, snackbarUI);
    }
  }

  const getOrganizations = async () => {
    try {
      blockUI.current?.open(true);
      const response = await organizationService.getOrganization();
      setListOrganizations(response?.data?.data);
    } catch (error) {
      blockUI.current?.open(false);
      AppHelper.checkError(error, snackbarUI);
    }
  }

  useEffect(() => {
    getAllRoles();
    getOrganizations();
  }, [organization.id])

  const onSubmit = (values) => {
    const OBJ = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: values?.email,
      role: values?.role,
      password: values.password,
      organizationIds: returnIdsSelected(values?.role, selectedOrg),
      companyIds: values?.companyIds ? [values?.companyIds] : [],
      //hasAccessToAllOrganizatons: hasAccessToAllOrganizatons(values),
      hasAccessToAllCompanies: hasAccessToAllCompanies(values),
      isPayroll: values.isPayroll,
      hasApiAccess: values.isApi,
      hasTmsAccess: values.isTms,
    };
    return handleSubmitData(OBJ);
  }

  /* const hasAccessToAllOrganizatons = (values) => {
    if(values.role === LISTROLES.superAdmin)return true;
    if(permissionsForOrganization(values.role))return values.hasAccessToAllOrganizatons;
    return false;
  } */
  
  const hasAccessToAllCompanies = (values) => {
    if(values.role === LISTROLES.superAdmin || permissionsForOrganization(values.role))return true;
    return false;
  }

  const returnIdsSelected = (role, currentList) => {
    if(role === LISTROLES.superAdmin)return [];
    if(currentList?.length){
      const list = currentList?.map((org) => org?.id)
      return list;
    }
    return [];
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmitData = async (data) => {
    try {
      blockUI.current?.open(true);
      id ? await userService.editUser(id, data) : await userService.addUser(data);
      blockUI.current?.open(false);
      history.push(ROUTENAME.userManagement)
    } catch (error) {
      blockUI.current?.open(false);
      AppHelper.checkError(error, snackbarUI);
    }
  }

  //reset some fields and show company list or organization list
  const handleChangeClick = (e, setFieldValue) => {
    //const value = e.target.dataset.value;
    const value = e.target.value;
    if(!value)return
    resetFields(setFieldValue);
    if(value === LISTROLES.superAdmin){
      setShowOrganization(false)
      setShowCompany(false)
      return
    }
    if(permissionsForOrganization(value)){
      setShowOrganization(true);
      setShowCompany(false);
      return
    }
    if(checkRoleForCompany(value)){
      setShowOrganization(true)
      setShowCompany(true)
      return;
    }
  }

  //if organization check is marked hiden de organization list
  /* const handleChangeOrganization = (e, setFieldValue) => {
    const isChecked = e.target.checked;
    setSelectedOrg([]);
    setFieldValue('organizationIds', '')
    if(isChecked)return setShowOrganization(false);
    return setShowOrganization(true);
  } */

  const resetFields = (setFieldValue) => {
    //setFieldValue('hasAccessToAllOrganizatons', false);
    setFieldValue('hasAccessToAllCompanies', false);
    setFieldValue('organizationIds', '')
    setFieldValue('companyIds', '')
    setSelectedOrg([]);
  }

  const handleChangeAutoOrg = (event, setFieldValue, role) => {
    setFieldValue('companyIds', '');
    if(!event.length){
      setSelectedOrg([]);
      return setFieldValue('organizationIds', '');
    };
    setFieldValue('organizationIds', 'value');
    if(checkRoleForCompany(role)){
      const lastElement = event.at(-1);
      const converToList = [lastElement];
      getCompanies(lastElement?.id);
      return setSelectedOrg(converToList);
    }
    setSelectedOrg(event);
  };

  const checkRoleForCompany = (role) => {
    if(role === LISTROLES.employerManager ||
      role === LISTROLES.employerReader ||
      role === LISTROLES.employerReporter
    )return true;
    return false;
  }
  
  useEffect(() => {
    if(!id && listOrganizations?.length && listRoles?.length)blockUI.current.open(false);
  }, [id, listOrganizations, listRoles])

  return (
    <Grid sx={style.container}>
      <Grid sx={style.containerBody}>
        <Typography sx={style.title}>{id ? 'Edit user' : 'Create user'}</Typography>
        
        <Grid >
          <Formik
            initialValues={formValues}
            validationSchema={validationEmployee}
            onSubmit={(values) => { onSubmit(values) }}
            enableReinitialize={true}
          >
            {(props) => {
              const {
                touched,
                errors,
                handleBlur,
                handleChange,
                setFieldValue,
                values,
              } = props;
              return (
                  <Form autoComplete="off" className="formCreateUser">
                      <Box sx={{...styleForm.inputsCtn, gridGap: '10px',}}>
                          <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                  <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                      <p>First Name: <span style={{color: "red"}}>*</span> </p>
                                      <AppTextField
                                          sx={styleForm.input}
                                          label="Type your first name here"
                                          variant="outlined"
                                          id="firstName"
                                          name="firstName"
                                      />
                                  </Box>
                              </Grid>

                              <Grid item xs={12} md={6}>
                                  <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                      <p>Last Name: <span style={{color: "red"}}>*</span> </p>
                                      <AppTextField
                                          sx={styleForm.input}
                                          label="Type your last name here"
                                          variant="outlined"
                                          id="lastName"
                                          name="lastName"
                                        />
                                  </Box>
                              </Grid>

                              <Grid item xs={12} md={6}>
                                  <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                      <p>Email: <span style={{color: "red"}}>*</span> </p>
                                      <AppTextField
                                          sx={styleForm.input}
                                          label="Type your email here"
                                          variant="outlined"
                                          id="email"
                                          name="email"
                                      />
                                  </Box>
                              </Grid>

                              <Grid item xs={12} md={6}>
                                <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                    <p>Password: <span style={{color: "red"}}>*</span> </p>
                                    <Box sx={{ position: 'relative'}}>
                                        <TextField
                                            label="Password"
                                            variant="outlined"
                                            autoComplete="new-password"
                                            id="password"
                                            name="password"
                                            defaultValue=""
                                            type={showPassword ? 'text' : 'password'}
                                            onChange={(e) => handleChange(e)}
                                            onBlur={handleBlur}
                                            error={touched.password && Boolean(errors.password)}
                                            sx={{...styleForm.input }}
                                        />

                                        <IconButton
                                            tabIndex={-1}
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword((prev) => !prev)}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            sx={generalStyle.showPassword}
                                        >
                                            {showPassword ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>

                                        {errors.password && touched.password && (
                                            <Typography sx={generalStyle.stateError}>{errors.password}</Typography>
                                        )}
                                    </Box>
                                </Box>
                              </Grid>

                              <Grid item xs={12} md={6}>
                                  <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                      <p>Role: <span style={{color: "red"}}>*</span> </p>
                                      <Select
                                          id="role"
                                          name='role'
                                          value={values.role || ''}
                                          sx={{
                                            ...styles.fieldsInput,
                                            height: '56px',
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              border: '1px solid #101010',
                                            },
                                          }}
                                          onChange={(e) => {
                                            handleChange(e);
                                            handleChangeClick(e, setFieldValue);
                                          }}
                                          onBlur={handleBlur}
                                          MenuProps={MenuItemProps}
                                          placeholder={'-- Select --'}
                                          error={touched.role && Boolean(errors.role)}
                                      >
                                          {listRoles?.map((item, index) => (
                                            <MenuItem
                                              value={item}
                                              key={index}
                                              disabled={disableRole(item)}
                                            >
                                              {showRoleName(item)}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                        {errors.role && touched.role && (
                                          <Typography style={{color: '#D32F2F', fontSize: '14px', marginLeft: '15px' }}>{errors.role}</Typography>
                                        )}
                                  </Box>
                              </Grid>

                              {showOrganization &&
                                <Grid item xs={12} md={6}>
                                  <FormControl fullWidth>
                                    <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                        <p>Organization: <span style={{color: "red"}}>*</span> </p>
                                        <Autocomplete
                                          multiple
                                          id="tags-outlined"
                                          options={listOrganizations}
                                          getOptionLabel={(option) => option.name}
                                          filterSelectedOptions
                                          value={selectedOrg}
                                          onChange={(e, newValue) => {handleChangeAutoOrg(newValue, setFieldValue, values?.role)}}
                                          name='organizationIds'
                                          sx={{ width: '100%', minHeight: '56px' }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label=""
                                              placeholder="Select..."
                                              sx={{...styleForm.input, '& .MuiOutlinedInput-root': {minHeight: '56px'}}}
                                              InputProps={{...params.InputProps }}
                                              error={!showOrganization || selectedOrg?.length ? null : touched.organizationIds && Boolean(errors.organizationIds)}
                                            />
                                          )}
                                        />
                                        <FormHelperText style={{color: '#D32F2F', fontSize: '14px', marginLeft: '15px' }}>
                                        {!showOrganization || selectedOrg?.length ? null : touched.organizationIds && errors.organizationIds}
                                        </FormHelperText>
                                    </Box>
                                  </FormControl>
                                </Grid>
                              }
                             
                              {showCompany &&
                                <Grid item xs={12} md={6}>
                                  <FormControl fullWidth>
                                    <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                        <p>Companies: <span style={{color: "red"}}>*</span> </p>
                                        <Select
                                          id="companyIds"
                                          name='companyIds'
                                          value={values.companyIds || ''}
                                          sx={{...styles.fieldsInput, height: '56px'}}
                                          onChange={(e) => {handleChange(e)}}
                                          onBlur={handleBlur}
                                          MenuProps={MenuItemProps}
                                          error={!showCompany ? null : touched.companyIds && Boolean(errors.companyIds)}
                                        >
                                          {listCompanies?.map((item, index) => (
                                            <MenuItem value={item?.id} key={index}>{item?.name}</MenuItem>
                                          ))}
                                        </Select>
                                        {errors.companyIds && touched.companyIds && (
                                          <Typography sx={generalStyle.stateError}>{errors.companyIds}</Typography>
                                        )}
                                    </Box>
                                  </FormControl>
                                </Grid>
                              }

                              {/* {values.role && permissionsForOrganization(values?.role) &&
                                <Grid container sx={{marginTop: '20px'}}>
                                  {permissionsForOrganization(values?.role) &&
                                    <Grid item xs={12} md={6}>
                                      <Box sx={{...styleForm.boxItem}} >
                                        <FormControlLabel
                                          checked={values.hasAccessToAllOrganizatons}
                                          disabled={false}
                                          name='hasAccessToAllOrganizatons'
                                          control={
                                            <Checkbox
                                              sx={{margin: '0px'}}
                                              color="success"
                                              onChange={(e) => {
                                                handleChange(e);
                                                handleChangeOrganization(e, setFieldValue);
                                              }}
                                            />
                                          }
                                          label='Has access to all organizations'
                                          labelPlacement="end"
                                          sx={userStyle.checkbox}
                                        />
                                      </Box>
                                    </Grid>
                                  }                                                 
                                  
                                  {checkAllCompanies(values?.role) &&
                                    <Grid item xs={12} md={6}>
                                      <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                        <FormControlLabel
                                          checked={values.hasAccessToAllCompanies}
                                          disabled={false}
                                          name='hasAccessToAllCompanies'
                                          control={
                                            <Checkbox
                                              sx={{margin: '0px'}}
                                              color="success"
                                              onChange={handleChange}
                                            />
                                          }
                                          label='Has access to all companies'
                                          labelPlacement="end"
                                          sx={userStyle.checkbox}
                                        />
                                      </Box>
                                    </Grid> 
                                  }                                                
                                </Grid>
                              } */}

                              {user?.role === LISTROLES.superAdmin
                                ? <Grid container sx={{marginTop: '20px'}}>
                                    <Grid item xs={12} md={6}>
                                      <Box sx={{...styleForm.boxItem}} >
                                        <FormControlLabel
                                          checked={values.isPayroll}
                                          disabled={false}
                                          name='isPayroll'
                                          control={
                                            <Checkbox
                                              sx={{margin: '0px 0px 0px 5px'}}
                                              color="success"
                                              onChange={(e) => {handleChange(e)}}
                                            />
                                          }
                                          label='Has access to payroll'
                                          labelPlacement="end"
                                          sx={style.checkbox}
                                        />
                                      </Box>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                      <Box sx={{...styleForm.boxItem}} >
                                        <FormControlLabel
                                          checked={values.isApi}
                                          disabled={false}
                                          name='isApi'
                                          control={
                                            <Checkbox
                                              sx={{margin: '0px 0px 0px 5px'}}
                                              color="success"
                                              onChange={(e) => {handleChange(e)}}
                                            />
                                          }
                                          label='Has access to API'
                                          labelPlacement="end"
                                          sx={style.checkbox}
                                        />
                                      </Box>
                                    </Grid>
                                    
                                    <Grid item xs={12} md={6}>
                                      <Box sx={{...styleForm.boxItem}} >
                                        <FormControlLabel
                                          checked={values.isTms}
                                          disabled={false}
                                          name='isTms'
                                          control={
                                            <Checkbox
                                              sx={{margin: '0px 0px 0px 5px'}}
                                              color="success"
                                              onChange={(e) => {handleChange(e)}}
                                            />
                                          }
                                          label='Has access to TMS'
                                          labelPlacement="end"
                                          sx={style.checkbox}
                                        />
                                      </Box>
                                    </Grid>
                                  </Grid>
                                : null
                              }
                          </Grid>
                      </Box>

                      <Box sx={styleForm.formButtonWrapper}>
                          <Button
                              color="inherit"
                              onClick={() => history.push(ROUTENAME.userManagement)}
                          >
                              Cancel
                          </Button>
                          <Button type="submit">{id ? 'Edit' : 'Create'}</Button>
                      </Box>
                  </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AddUser