/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { createContext, useEffect, useMemo, useState } from 'react'
import { Container, Grid } from '@mui/material';

// @assets
import { GeneralStyles } from '../../assets/css/general-style';

// @components
import Pageheader from '../../components/PageHeader';
import TicketTable from './components/TicketTable';

const list = [
  {
    id: 1,
    name: 'Marquiñes Perez',
    status: 'Open', 
    email: 'exampleemail@gmail.com', 
    priority: 'High priority',
    createDate: '01/18/2025'
  },
  {
    id: 2,
    name: 'Xboom Saenz',
    status: 'Resolved', 
    email: 'exampleemail2@gmail.com', 
    priority: 'Medium priority',
    createDate: '01/18/2025'
  },
  {
    id: 3,
    name: 'Ximena Sarate',
    status: 'Processing', 
    email: 'exampleemail2@gmail.com', 
    priority: 'Low priority',
    createDate: '01/18/2025'
  },
]

const TicketManagement = () => {
  const generalStyle = useMemo(() => GeneralStyles(), []);

  const [rowsState, setRowsState] = useState({
    pageSize: 10,
    rows: [],
    rowCount: 0,
    currentPage: 1,
    page: 0,
  });

  const handleMapResponse = async () => {
    return await setRowsState((prev) => ({
      ...prev,
      rows: list?.length ? mapResponse(list) : [],
      rowCount: list?.length
    }));
  }

  const mapResponse = (response) => {
    const newResponse = response.map((item) => {
      return {
        id: item?.id,
        name: item?.name,
        status: item?.status,
        email: item?.email,
        priority: item?.priority,
        createDate: item?.createDate
      }
    });
    return newResponse;
  }

  useEffect(() => {
    handleMapResponse();
  }, [])

  return (
      <Container
        component="section"
        maxWidth="xl"
        sx={generalStyle.dashCtn}
      >
        <Container
          component="section"
          maxWidth="xl"
          sx={generalStyle.containerTitle}
        >
          <Pageheader
            title={'Ticket management'}
            subtitle={'Welcome to ticket page'}
          />
        </Container>
  
        <TicketContext.Provider value={{
            rowsState,
            setRowsState
          }}
        >
          <Grid>
            <TicketTable/>
          </Grid>
        </TicketContext.Provider>
      </Container>
    )
  }
    
export const TicketContext = createContext({});
export default TicketManagement