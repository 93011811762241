// @vendors
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux';

// @style
import { GeneralStyles } from '../../../assets/css/general-style';

// @components material
import {
  AddIcon,
  Box,
  Button,
  Grid,
} from '../../../components/shared/MaterialUI';

// @components
import AddNewOrganization from './AddNewOrganization';

// @helpers
import { LISTROLES } from '../../../helpers/ListRoles';

const ToolbarOrganizations = () => {
  const generalStyle = useMemo(() => GeneralStyles(), []);
  const [openModal, setOpenModal] = useState(false);
  const user = useSelector((state) => state.user);

  return (
    <Box sx={generalStyle.containerToolbar}>
          <Grid sx={generalStyle.gridToolbar}>
            <Grid><h2>Organizations list</h2> </Grid>

            {user?.role === LISTROLES.superAdmin &&
              <Grid>
                <Button
                  variant='outlined'
                  endIcon={<AddIcon />}
                  sx={generalStyle.button}
                  onClick={() =>setOpenModal(true)}
                >
                  Add New Organization
                </Button>
              </Grid>
            }
          </Grid>

        <AddNewOrganization
          open={openModal}
          onClose={() => setOpenModal(false)}
          isEdit={false}
        />
    </Box>
  )
}

export default ToolbarOrganizations