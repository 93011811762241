// @vendors
import React, { useContext, useMemo } from 'react'

// @assets
import { EmployeeTableStyles } from '../../assets/css/employeeTable-style';

// @components
import ToolbarOrganizations from './Components/ToolbarOrganizations';
import OrganizationRow from './Components/OrganizationRow';

// @context
import { Organization } from './OrganizationScreen';

// @components material
import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
} from '../../components/shared/MaterialUI';

const ListOrganizations = () => {
  const styles = useMemo(() => EmployeeTableStyles(), []);
  const { rowsState  } = useContext(Organization);

  return (
    <Paper elevation={4}>
        <ToolbarOrganizations />
        <TableContainer sx={styles.tableContainer}>
          <Table aria-label="collapsible table" stickyHeader>
            <TableHead>
              <TableRow sx={styles.tableRow}>

                <TableCell sx={{ ...styles.tableHeadTitle, textAlign: 'left', paddingLeft: '30px' }}>
                  Name
                </TableCell>

                <TableCell sx={{ ...styles.tableHeadTitle, textAlign: 'left' }}>
                  Status
                </TableCell>
                    
                <TableCell sx={styles.emptyTableCell} />
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsState?.length
                ? React.Children.toArray(rowsState?.map((row) => <OrganizationRow row={row} />))
                : null
              }
            </TableBody>
          </Table>
        </TableContainer>
    </Paper>
  )
}

export default ListOrganizations