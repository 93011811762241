/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { useMemo, useRef, useState } from 'react'
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

// @assets
import { EmployerStyles } from '../../../assets/css/employer-style';

// @context
import { useUI } from '../../../app/context/ui';

// @services
import QuestionnaireServiceNewApi from '../../../services/newApi/QuestionnaireServiceNewApi';

// @helpers
import AppHelper from '../../../helpers/AppHelper';
import { formDataUploadEmployees } from '../../../helpers/FunctionsUtils';

const DriverLicense = ({
  lang,
  hashToken,
  updateQuestion,
  initial
}) => {
  const styles = useMemo(() => EmployerStyles(), []);
  const { blockUI, snackbarUI } = useUI();
  const newQuestionService = useMemo(() => new QuestionnaireServiceNewApi(), []);
  const history = useHistory();
  const videoRef = useRef(null);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const photoRef = useRef(null);

  const getVideo = () => {
    setIsCameraOn(true);
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        console.error('Error accessing camera: ', err);
      });
  };
  
  const takePhoto = () => {
    const width = 320;
    const height = 240;
    let video = videoRef.current;
    let photo = photoRef.current;
    photo.width = width;
    photo.height = height;
    let ctx = photo.getContext('2d');
    ctx.drawImage(video, 0, 0, width, height);
    photo.toBlob((blob) => {
      if (blob) {
        uploadPhoto(blob);
      }
    },'image/jpeg', 0.9);
  };

  const handleClickFile = () => {
    const file = document.getElementById('upload-input-license')
    file?.click()
  }
  
  const uploadPhoto = (blob) => {
    if (blob) {
      const formData = new FormData();
      formData.append('file', blob, 'photo.jpg');
      handleSubmit(formData);
    }
  };
  
  const uploadFile = (e) => {
    if (e.target.files[0]) {
      const file = formDataUploadEmployees(e.target.files[0]);
      handleSubmit(file, e);
    }
  };

  const handleSubmit = async (value, e) => {
    try {
      blockUI.current.open(true);
      await newQuestionService.uploadDriverLicense(hashToken, value);
      document.title = '';
      const res = await newQuestionService.questStartNewApi(hashToken, lang);
      blockUI.current.open(false);
      updateQuestion(res?.data?.data[0]);
    } catch (err) {
      blockUI.current.open(false);
      AppHelper.checkError(err, snackbarUI);
      if(e)e.target.value = '';
    }
  }
  
  const showText = () => {
    const textes = 'Para garantizar que se procese su cuestionario de evaluación de WOTC, debe cargar una imagen clara del frente de su licencia de conducir actual (no vencida). Este paso es esencial para verificar su información y completar el proceso de evaluación. Si no carga una licencia de conducir válida, no se procesará su cuestionario. Una vez que haya seleccionado y cargado la imagen, el sistema la almacenará automáticamente de forma segura y lo dirigirá sin problemas a la página de confirmación, indicando que su envío está completo. Asegúrese de que la imagen sea clara y que todos los detalles sean visibles para evitar demoras en el procesamiento.';
    const texten = 'To ensure your WOTC screening questionnaire is processed, you must upload a clear image of the front of your current (not expired) driver’s license. This step is essential for verifying your information and completing the screening process. Without a valid driver’s license upload, your questionnaire will not be processed. Once you have selected and uploaded the image, the system will automatically store it securely and seamlessly direct you to the confirmation page, indicating that your submission is complete. Please make sure the image is clear and all details are visible to avoid any delays in processing.';
    if(lang === 'es')return textes;
    return texten;
  }

  const handleSkip = () => {
    const params = lang ? `${hashToken}?lang=${lang}` : `${hashToken}`;
    if(initial?.isVeteran && !initial?.hasDD214Answer){
      return history.push(`/upload-file-dd214/${params}`);
    }
    return history.push(`/saved-veteran-question/${params}`);
  }

  return (
    <>      
      <Paper
        elevation={4}
        sx={{
          margin: {xs: '0px 10px 10px 10px', md: '0px 50px 50px 50px'},
          width: '100%',
        }}
      >
          <Grid
            sx={{
              ...styles.containerDownload,
              minHeight: "30vh",
              maxHeight: "70vh",
              gap: '20px',
              overflow: 'auto'
            }}
          >
              <Typography
                variant="h5"
                sx={{
                  marginTop: '30px',
                  fontSize: '19px',
                  lineHeight: '25px',
                }}
              >
                {showText()}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: {xs: 'column', md: 'row'},
                  gap: {xs: '20px', md: '0px'},
                  justifyContent: 'space-around',
                  margin: '10px 0px',
                  width: '100%',
                }}
              >
                <Button
                  variant='contained'
                  onClick={handleSkip}
                  sx={{
                    color: '#fff',
                    backgroundColor: '#000',
                    '&: hover': {backgroundColor: '#343434'},
                    height: '40px'
                  }}
                >
                  I don't have it now
                </Button>

                <Button
                  variant='contained'
                  onClick={getVideo}
                  sx={{
                    color: '#fff',
                    backgroundColor: '#000',
                    '&: hover': {backgroundColor: '#343434'},
                    height: '40px'
                  }}
                >
                  Take photo
                </Button>

                <Button
                  variant='contained'
                  onClick={handleClickFile}
                  sx={{
                    color: '#fff',
                    backgroundColor: '#000',
                    '&: hover': {backgroundColor: '#343434'},
                    height: '40px'
                  }}
                >
                  upload image
                </Button>
              </Box>
                
              {isCameraOn && 
                <Grid
                  sx={{
                    display: 'flex',
                    flexDirection: {xs: 'column', md: 'column'},
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: {xs: '0px', md: '20px'},
                  }}
                >
                  <video ref={videoRef} style={{width: '250px', height: '250px'}}></video>
                  <Button
                    variant='contained'
                    onClick={takePhoto}
                    sx={{
                      color: '#fff',
                      backgroundColor: '#000',
                      '&: hover': {backgroundColor: '#343434'},
                      height: '40px'
                    }}
                  >
                    save
                  </Button>
                  <canvas ref={photoRef} style={{display: 'none'}}></canvas>
                </Grid>
              }

              <input
                type='file'
                accept="image/*"
                id='upload-input-license'
                style={{display: 'none'}}
                onChange={(e) => uploadFile(e)}
              />
          </Grid>
      </Paper>
    </>
  )
}

export default DriverLicense