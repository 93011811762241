// @vendors
import React, { useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom';

// @components material
import {
  AddIcon,
  Button,
  Grid,
  Box,
  InputAdornment,
  IconButton,
  SearchRoundedIcon,
  Tooltip,
  ClearAllIcon,
  TextField
} from '../../../components/shared/MaterialUI';

// @assets
import { toolbarStyles } from '../../../assets/css/customToolbar-styles';
import { GeneralStyles } from '../../../assets/css/general-style';

// @constants
import { ROUTENAME } from '../../../navigation/RouteName';

// @context
import { UserContext } from '../UserManagement';

const ToolbarUser = () => {
  const styles = useMemo(() => GeneralStyles(), []);
  const stylesTextField = useMemo(() => toolbarStyles(), []);
  const history = useHistory();
  const {
    getUsers,
    search,
    setSearch,
    pages
  } = useContext(UserContext)

  const onSearchBarChange = (e) => {
    setSearch((current) => ({
      ...current,
      fullName: e.target.value
    }));
  };

  const clearSearch = () => {
    setSearch((current) => ({
      ...current,
      fullName: ''
    }));
    getUsers(1, pages.size, {});
  };

  const handleSearchBar = async (query) => {
    if(query){
      getUsers(1, pages.size);
    }
  };

  const actionToSelect = () => {
    if(search?.fullName === '')return clearSearch();
    if(search?.fullName?.length > 0)return handleSearchBar(search?.fullName);
    return
  }

  return (
    <Box sx={styles.containerToolbar}>
      <Grid sx={styles.gridToolbar}>
        <Grid><h2>User list</h2> </Grid>

        <Grid sx={stylesTextField.gridToolbarField}>
          <TextField
            sx={{...stylesTextField.searchBox, ...stylesTextField.searchBoxSm}}
            value={search?.fullName}
            id='searchCompany'
            placeholder='Search by full name'
            onChange={(e) => onSearchBarChange(e)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if(search?.fullName === '') return clearSearch()
                return handleSearchBar(search?.fullName);
              }
            }}
            variant='outlined'
            InputProps={{
              endAdornment: (
                <InputAdornment position='start'>
                  <IconButton onClick={actionToSelect}>
                    <SearchRoundedIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Tooltip title="Clear filter">
            <IconButton
              onClick={() => clearSearch()}
              sx={{width: '40px', height: '40px'}}
            >
              <ClearAllIcon />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid sx={stylesTextField.btnAddUser}>
          <Button
            variant='outlined'
            endIcon={<AddIcon />}
            sx={styles.button}
            onClick={() => history.push(ROUTENAME.addUser)}
          >
            Add New User
          </Button>
        </Grid>  
      </Grid>
    </Box>
  )
}

export default ToolbarUser