// @vendors
import React, { useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';

// @components material
import {
  Button,
  EditIcon,
  ExpandMoreRoundedIcon,
  Grid,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Tooltip,
} from '../../../components/shared/MaterialUI';

// @assets
import { MenuProps } from '../../../assets/css/tableComponents-styles';
import { DashboardStyles } from '../../../assets/css/dashboard-style';
import { GeneralStyles } from '../../../assets/css/general-style';

// @context
import { useUI } from '../../../app/context/ui';

// @services
import OrganizationServiceNewApi from '../../../services/newApi/OrganizationService';

// @helpers
import AppHelper from '../../../helpers/AppHelper';
import { LISTROLES } from '../../../helpers/ListRoles';

// @components
import { Organization } from '../OrganizationScreen';
import AddNewOrganization from './AddNewOrganization';

const OrganizationRow = ({row}) => {
  const generalStyle = useMemo(() => GeneralStyles(), []);
  const styleSelect = useMemo(() => DashboardStyles(), []);
  const { blockUI, snackbarUI } = useUI();
  const statusOptions = ["enable", "disable"];
  const organizationService = useMemo(() => new OrganizationServiceNewApi(), []);
  const {getListOrganization } = useContext(Organization);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const user = useSelector((state) => state.user);

  const onStatusChange = async (e) => {
      const OBJ = {
          name: row?.name,
          status: e.target.value,
          owner: row?.owner,
          infoEmail: row?.infoEmail,
          senderEmail: row?.senderEmail,
          homeUrl: row?.homeUrl
      }
      dataToUpdate(OBJ);
  };

  const dataToUpdate = async (obj) => {
    try {
        blockUI.current.open(true);
        await organizationService.updateOrganization(row?.id, obj);
        getListOrganization();
    } catch (e) {
        blockUI.current.open(false);
        AppHelper.checkError(e, snackbarUI);
    } 
  }

  const handleOpenModalEdit = () => {
    setOpenModalEdit(true);
  }

  const permissionForEdit = () => {
    if(user?.role === LISTROLES.superAdmin || user?.role === LISTROLES.orgManager)return true;
    return false;
  }

  return (
    <>
      <TableRow>

        <TableCell
          scope="row"
          sx={{...generalStyle.tableCell, textAlign: 'left', paddingLeft: '30px'}}
        >
          {row.name}
        </TableCell>    
               
        <TableCell
          scope="row"
          sx={{...generalStyle.tableCell, textAlign: 'left'}}
        >
          <Select
          value={row.status}
          IconComponent={ExpandMoreRoundedIcon}
          onChange={(e) => onStatusChange(e)}
          sx={{...styleSelect.statusSelect, pointerEvents: permissionForEdit() ? 'auto' : 'none'}}
          MenuProps={MenuProps}
        >
          {statusOptions.map((status, index) => (
            <MenuItem
              value={status}
              key={index}
              sx={{ textTransform: "capitalize" }}
            >
              {status}
            </MenuItem>
          ))}
        </Select>
        </TableCell>

        {permissionForEdit() &&
          <TableCell
            sx={{
              ...generalStyle.tableCell,
              ...generalStyle.tableColumnHide,
              padding: "23px 2.5em 16px 16px",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{display: 'flex', justifyContent: 'center'}}
            >
              <Tooltip
                title={<p style={{ fontSize: "16px", margin: "0" }}>Edit organization</p>}
                arrow
              >
                  <Button
                    variant="contained"
                    onClick={() => handleOpenModalEdit()}
                    style={{ backgroundColor: "transparent", boxShadow: "none" }}
                    children={
                      <EditIcon style={{ color: "#101010", fontSize: "2rem" }} />
                    }
                  />
              </Tooltip>
            </Grid>
          </TableCell>
        }      
      </TableRow>
      <AddNewOrganization
        open={openModalEdit}
        onClose={() => setOpenModalEdit(false)}
        isEdit={true}
        data={row}
      />
    </>
  )
}

export default OrganizationRow