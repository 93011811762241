// @vendors
import React, { useMemo } from 'react'
import { Grid, Typography } from '@mui/material'

// @assets
import { TicketStyles } from '../../../assets/css/general-style'

const TicketDetail = ({row}) => {
    const style = useMemo(() => TicketStyles(), []);

  return (
    <Grid
        container
        spacing={2}
        sx={style.container}
    >
        <Grid item xs={12} md={6} xl={4}>
            <Typography sx={style.titleHeader}>Jira Link</Typography>
            <Typography
                sx={{ ...style.subTitleHeader, ...style.subTitleHeaderHover }}
                onClick={() => window.open('https://www.youtube.com/', '_blank')}
            >
                https://techgenies.atlassian.net/jira/software/projects/RB2/boards/11?selectedIssue=RB2-2425
            </Typography>
        </Grid>
        
        <Grid item xs={12} md={6} xl={4}>
            <Typography sx={style.titleHeader}>Screen issue</Typography>
            <Typography
                sx={{ ...style.subTitleHeader, ...style.subTitleHeaderHover }}
                onClick={() => window.open('https://www.youtube.com/', '_blank')}
            >
                https://www.youtube.com/csac
            </Typography>
        </Grid>
        
        <Grid item xs={12} md={6} xl={4} >
            <Typography sx={style.titleHeader}>Function impact</Typography>
            <Typography sx={style.subTitleHeader}>Employee profile, hubspot automation, In commodo laboris velit culpa quis ea adipisicing adipisicing fugiat.</Typography>
        </Grid>
        
        <Grid item xs={12} md={6} xl={4} >
            <Typography sx={style.titleHeader}>Location issue</Typography>
            <Typography sx={style.subTitleHeader}>Nulla labore duis veniam cillum anim laboris tempor Lorem nulla Lorem velit ea cupidatat.</Typography>
        </Grid>
        
        <Grid item xs={12} md={6} xl={4} >
            <Typography sx={style.titleHeader}>Responsible Email</Typography>
            <Typography sx={style.subTitleHeader}>Examplefdsfdfsdsfdsfdsfdemail@gmail.com</Typography>
        </Grid>
        
        <Grid item xs={12} md={6} xl={4} >
            <Typography sx={style.titleHeader}>Client name</Typography>
            <Typography sx={style.subTitleHeader}>Arlei gfdgfdgfdg fdgfg</Typography>
        </Grid>
        
        <Grid item xs={12} md={6} xl={4} >
            <Typography sx={style.titleHeader}>Employee name</Typography>
            <Typography sx={style.subTitleHeader}>Arlei test fdgrfgfdgfd dsdfsdfd</Typography>
        </Grid>
        
        <Grid item xs={12} md={6} xl={4} >
            <Typography sx={style.titleHeader}>Employee email</Typography>
            <Typography sx={style.subTitleHeader}>Arleitedsadasdsadss@gmail.com</Typography>
        </Grid>
        
        <Grid item xs={12} md={6} xl={4} >
            <Typography sx={style.titleHeader}>Data field affect</Typography>
            <Typography sx={style.subTitleHeader}></Typography>
        </Grid>

        <Grid item xs={12} md={6} xl={6} >
            <Typography sx={style.titleHeader}>Description</Typography>
            <Typography sx={style.subTitleHeader}>Irure eiusmod fugiat tempor ex. Ex quis elit adipisicing nisi nisi sit in ea excepteur cupidatat culpa irure. Adipisicing ipsum ea culpa ipsum pariatur proident consequat laborum occaecat esse elit in. Consectetur eiusmod deserunt ex in sunt cillum voluptate.</Typography>
        </Grid>
        
        <Grid item xs={12} md={6} xl={6} >
            <Typography sx={style.titleHeader}>Response from dev</Typography>
            <Typography sx={style.subTitleHeader}>Irure eiusmod fugiat tempor ex. Ex quis elit adipisicing nisi nisi sit in ea excepteur cupidatat culpa irure. Adipisicing ipsum ea culpa ipsum pariatur proident consequat laborum occaecat esse elit in.</Typography>
        </Grid>
    </Grid>
  )
}

export default TicketDetail