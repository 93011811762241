/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { useEffect, useMemo, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Button,
    Dialog,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@mui/material';

// @assets
import { EmployeeTableStyles } from '../../../../assets/css/employeeTable-style';
import { TableInvoiceStyle } from '../../../../assets/css/payroll-style';

// @components
import { TablePaginationActions } from '../../../Admin/components/custom/TablePaginationActions';
import CustomNoRows from '../../../../components/CustomNoRows';

// @services
import PayrollService from '../../../../services/newApi/PayrollService';

// @context
import { useUI } from '../../../../app/context/ui';

// @helpers
import AppHelper from '../../../../helpers/AppHelper';

const EmployeesInvoiceModal = ({open, handleClose, companyId}) => {
    const styles = useMemo(() => EmployeeTableStyles(), []);
    const style = useMemo(() => TableInvoiceStyle(), []);
    const { blockUI, snackbarUI } = useUI();
    const payrollServise = useMemo(() => new PayrollService(), []);
    const [employeeList, setEmployeeList] = useState({
        currentPage: 1,
        pageSize: 5,
        rows: [],
        page:0,
        rowCount: 0
    });

    const handlePageChange = (event, nextPage) => {
        const page = nextPage === 0 ? 1 : nextPage === employeeList.currentPage ? nextPage + 1 : nextPage
        setEmployeeList((prev) => ({
          ...prev,
          currentPage: parseInt(page),
          pageSize: employeeList.pageSize
        }));
        getInvoiceList(page, employeeList.pageSize);
    };
    
    const handlePageSizeChange = (event) => {
        setEmployeeList((prev) => ({
          ...prev,
          currentPage: 1,
          pageSize: event.target.value
        }));
        getInvoiceList(1, event.target.value);
    };

    const getInvoiceList = async (page, size) => {
        const currentPage = page ? page : employeeList.currentPage;
        const currentSize = size ? size : employeeList.pageSize;
        try {
            blockUI.current.open(true);
            const response = await payrollServise.getAllInvoice(companyId, currentPage, currentSize);
            updateList(response?.data);
            blockUI.current.open(false);
        } catch (error) {
            blockUI.current.open(false);
            AppHelper.checkError(error, snackbarUI);
        }
    }

    const updateList = (response) => {
        setEmployeeList((prev) => ({
            ...prev,
            currentPage: response?.pagination?.pageNumber,
            pageSize: response?.pagination?.pageSize ?? 5,
            rows: response?.data ?? [],
            page: response?.pagination?.pageNumber -1,
            rowCount: response?.pagination?.totalRecords
        }))
    }

    useEffect(() => {
        if(companyId && open)getInvoiceList();
    }, [companyId, open])

    const handleCloseModal = () => {
        setEmployeeList({
            currentPage: 1,
            pageSize: 5,
            rows: [],
            page:0,
            rowCount: 0
        });
        handleClose();
    }
    
  return (
    <Dialog
        sx={{
            '& .MuiDialog-paper': {
            width: '100%',
            backgroundColor: '#fff',
            minHeight: '250px',
            padding: '24px',
            borderRadius: '9px',
            boxSizing: 'border-box !important',
            overflow: 'hidden'
        },
        }}
        open={open}
        fullWidth={true}
        maxWidth={'md'}
    >
        <Box sx={{padding: '20px'}}>
            <TableContainer sx={{...styles.tableContainer, minHeight: '250px'}}>
                <Box sx={style.containerTitle}>
                    <Typography  raphy sx={styles.titleModal}>Employees by employer</Typography>
                    <Button
                        children={<CloseIcon/>}
                        onClick={handleCloseModal}
                        style={{ backgroundColor: "#ECF8F7", color: '#007F6D' }}
                    />
                </Box>
                <Table
                    aria-label="collapsible table"
                    stickyHeader
                    sx={{marginTop: '30px'}}
                >
                    <TableHead>
                        <TableRow sx={{minHeight: '70px',}}>
                            <TableCell sx={{...style.tableHeadTitle}}>Employee Name</TableCell>
                            <TableCell sx={{...style.tableHeadTitle}}>Invoice YTD ($)</TableCell>
                            <TableCell sx={{...style.tableHeadTitle}}>Actual Invoice ($)</TableCell>
                            <TableCell sx={{...style.tableHeadTitle}}>New Credits</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {employeeList?.rows?.map((item, index) => (
                            <TableRow sx={{minHeight: '70px',}} key={index}>
                                <TableCell sx={{...style.tableHeadTitle, fontWeight: '300'}}>{item?.fullName}</TableCell>
                                <TableCell sx={{...style.tableHeadTitle, fontWeight: '300'}}>{item?.ytdInvoice}</TableCell>
                                <TableCell sx={{...style.tableHeadTitle, fontWeight: '300'}}>{item?.actualInvoice}</TableCell>
                                <TableCell sx={{...style.tableHeadTitle, fontWeight: '300'}}>{item?.credits ?? 0}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {!employeeList?.rows.length
                    ?    <Box
                            sx={{
                                width: '100% !important',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '30px'
                            }}
                        >
                            <CustomNoRows/>
                        </Box>
                    :   null
                }
            </TableContainer>
            <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 20, 50]}
                colSpan={3}
                count={employeeList.rowCount || 0}
                rowsPerPage={parseInt(employeeList.pageSize)}
                page={employeeList.page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={(e) => handlePageSizeChange(e)}
                ActionsComponent={() => TablePaginationActions(employeeList.rowCount, employeeList.currentPage, employeeList.pageSize, handlePageChange)}
                SelectProps={{
                    inputProps: {
                    "aria-label": "rows per page",
                    },
                    native: true,
                }}
            />

        </Box>
        
        </Dialog>
    )
}

export default EmployeesInvoiceModal