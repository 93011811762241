// @vendors
import React, { useContext, useMemo } from 'react'

// @components material 
import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TablePagination,
} from '../../../components/shared/MaterialUI';
 
// @assets
import { EmployeeTableStyles } from '../../../assets/css/employeeTable-style';

// @components
import ToolbarUser from './ToolbarUser';
import UserRow from './UserRow';
import { TablePaginationActions } from '../../Admin/components/custom/TablePaginationActions';

// @context
import { UserContext } from '../UserManagement';

const UserTable = () => {
  const styles = useMemo(() => EmployeeTableStyles(), []);
  const {
    rowsState,
    pages,
    setPages,
    getUsers,
  } = useContext(UserContext);

  const handlePageChange = (event, nextPage) => {
    const page = nextPage === 0 ? 1 : nextPage === pages.page ? nextPage + 1 : nextPage
    setPages((prev) => ({
      ...prev,
      page: parseInt(page),
      size:pages.size
    }));
    getUsers(page, parseInt(pages.size))
  };
  
  const handlePageSizeChange = (event) => {
    setPages((prev) => ({
      ...prev,
      page: 1,
      size: event.target.value
    }));
    getUsers(1, event.target.value)
  };

  return (
    <Paper elevation={4}>
        <ToolbarUser />
        <TableContainer sx={styles.tableContainer}>
          <Table aria-label="collapsible table" stickyHeader>
            <TableHead>
              <TableRow sx={styles.tableRow}>
                <TableCell sx={{ ...styles.tableHeadTitle, textAlign: 'left', paddingLeft: '30px' }}>
                  Full Name
                </TableCell>

                <TableCell sx={{ ...styles.tableHeadTitle, ...styles.tableColumnHide, textAlign: 'left' }}>
                  Email
                </TableCell>

                <TableCell sx={{ ...styles.tableHeadTitle, textAlign: 'left' }}>
                  Permission
                </TableCell>

                <TableCell sx={{ ...styles.tableHeadTitle, textAlign: 'left' }}>
                  Status
                </TableCell>
                    
                <TableCell sx={styles.emptyTableCell} />
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsState?.rows?.length
                ? React.Children.toArray(rowsState?.rows?.map((row) => <UserRow row={row} />))
                : null
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 20, 50, 100]}
          colSpan={3}
          count={rowsState.rowCount || 0}
          rowsPerPage={parseInt(pages.size)}
          page={pages.page < 0 || pages.page === 0 ? 0 : pages.page - 1}
          onPageChange={handlePageChange}
          onRowsPerPageChange={(e) => handlePageSizeChange(e)}
          ActionsComponent={() => TablePaginationActions(rowsState.rowCount, pages.page, pages.size, handlePageChange)}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
        />
    </Paper>
  )
}

export default UserTable