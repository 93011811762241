import React from 'react';

import {
  	Box,
  	useGridApiContext,
	Switch,
	Typography,
	Button,
} from '../../../../components/shared/MaterialUI.js';

export const CustomColumnsPanel = () => {
  	const apiRef = useGridApiContext();
	const { getAllColumns, setColumnVisibility, getVisibleColumns } = apiRef.current;
	const columnsVisibility = getVisibleColumns();
	const columns = getAllColumns().map((column) => ({field: column.field, name: column.headerName || 'Action Buttons', hide: column.hide}));

	const hideAllColumns = () => {
		if (columnsVisibility.length === 0) {
			return;
		}
		columns.forEach((column) => {
			setColumnVisibility(column.field, false);
		} );
	}

	const showAllColumns = () => {
		if (columnsVisibility.length === columns.length) {
			return;
		}
		columns.forEach((column) => {
			setColumnVisibility(column.field, true);
		} );
	}

  return (
    <>
		<Box 
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '2rem',
				padding: {xs: '24px 0px', xl: '24px'},
				boxSizing: 'border-box !important',
			}}
		>
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					flexWrap: 'wrap',
					gap: {xs: '8px', xl: '36px'},
				}}
			>
			{
				columns.map((column, index) => {
					return (
						<Box key={column.field} sx={{
							display: 'flex',
							alignItems: 'center',
						}}>
							<Switch
								onChange={() => {
									setColumnVisibility(column.field, column.hide);
								}}
								checked={!column.hide}
								color="success"
							/>
							<Typography>{column.name}</Typography>
						</Box>
					);
				}
				)
			}
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-around',
				}}
			>
				<Button	onClick={hideAllColumns}>
					Hide All
				</Button>

				<Button	onClick={showAllColumns}>
					Show All
				</Button>
			</Box>
		</Box>
    </>
  );
};
