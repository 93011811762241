/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { useMemo } from 'react'
import { TableCell, TableRow } from '@mui/material'

// @assets
import { EditPayrollStyle } from '../../../assets/css/payroll-style'

// @helpers
import {
    convertNameStateStatus,
    formattDate,
    getTargetGroup,
    getTargetGroupTooltip,
    HtmlTooltip,
} from '../../../helpers/FunctionsUtils'

const FormEditDetail = ({data}) => {
    const style = useMemo(() => EditPayrollStyle(), []);

  return (
    <TableRow sx={{display:'flex'}}>
        <TableCell sx={style.tableCellRow}>{formattDate(data?.lastSyncDate)}</TableCell>
        <TableCell sx={style.tableCellRow}>{convertNameStateStatus(data?.stateStatus)}</TableCell>
        <TableCell sx={style.tableCellRow}>{data?.employeeFullName}</TableCell>
        <TableCell sx={style.tableCellRow}>{data?.employeer}</TableCell>
        <TableCell sx={style.tableCellRow}>{data?.employeerRate}</TableCell>
        <TableCell sx={style.tableCellRow} >
            <HtmlTooltip title={getTargetGroupTooltip(data?.targetGroups) ?? ''} placement="right-start">
                {getTargetGroup(data?.targetGroups, false)}
          </HtmlTooltip>
        </TableCell>

        <TableCell sx={style.tableCellRow}>
            {getTargetGroup(data?.targetGroups, true)}
        </TableCell>

        <TableCell sx={style.tableCellRow}>{formattDate(data?.startDate)}</TableCell>

        <TableCell sx={style.tableCellRow}>
            {data?.paySchedule}
        </TableCell>

        <TableCell sx={style.tableCellRow}>
            {data?.currentYTDGrossWages}
        </TableCell>

        <TableCell sx={style.tableCellRow} >{data?.currentYTDHours}</TableCell>
        <TableCell sx={style.tableCellRow} >{data?.wotcCreditMultiplier}</TableCell>
        <TableCell sx={style.tableCellRow} >{data?.maxWage}</TableCell>
        <TableCell sx={style.tableCellRow} >{data?.maxTaxCredit}</TableCell>
        <TableCell sx={style.tableCellRow} >{data?.taxCreditYTD}</TableCell>
        <TableCell sx={style.tableCellRow} >{data?.invoiceYTD}</TableCell>
        <TableCell sx={style.tableCellRow} >{data?.actualInvoice}</TableCell>   
    </TableRow>
  )
}

export default FormEditDetail