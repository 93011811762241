/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { useContext, useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
    Autocomplete,
    Box,
    FormControl,
    Grid,
    IconButton,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { debounce } from 'lodash';

// @context
import { payrollContext } from '../../payrollView';
import { useUI } from '../../../../app/context/ui';

// @assets
import { DialogFilterPayroll } from '../../../../assets/css/payroll-style';

// @helpers
import AppHelper from '../../../../helpers/AppHelper';
import { getOrganizations } from '../../../../helpers/FunctionsUtils';

// @services
import CompanyServiceNewApi from '../../../../services/newApi/CompanyServiceNewApi';

// @components
import { ClearAllIcon, SearchRoundedIcon } from '../../../../components/shared/MaterialUI';

const FilterCompany = () => {
    const style = useMemo(() => DialogFilterPayroll(), []);
    const { blockUI, snackbarUI } = useUI();
    const companySNewApi = useMemo(() => new CompanyServiceNewApi(), []);
    const {
        getRecordsList,
        setFilterModal,
    } = useContext(payrollContext);
    const [initialValues, setInitialValues] = useState({organization: '', nameEmployer: '',});
    const [valueCompany, setValueCompany] = useState();
    const [inputValue, setInputValue] = React.useState('');
    const [organizationList, setOrganizationList] = useState([]);
    const [listCompanies, setListCompanies] = useState([]);

    const validationForm = Yup.object({
        organization: Yup.string().notRequired().nullable(),
        nameEmployer: Yup.string().notRequired().nullable(),
    });

    const formik = useFormik({
        initialValues:initialValues,
        onSubmit:(values) => {
          submit(values)
        },
        validationSchema: validationForm
    })

    const submit = () => {
        const DATA = {
          organization: initialValues.organization ? initialValues.organization : null,
          Employeer: valueCompany ? valueCompany : null,
        };
        const params = {filters: DATA};
        getRecordsList(params);
        setFilterModal(DATA);
    }

    const getOrganization = async () => {
        try {
          const response = await getOrganizations(snackbarUI, blockUI);
          setOrganizationList(response?.data?.data);
        } catch (error) {
          AppHelper.checkError(error, snackbarUI);
        }
    }

    const getAllCompanies = async (org, filters={}) => {
        const sort = {order: true, direction: 'asc' }
        try {
          blockUI.current.open(true);
          const response = await companySNewApi.getCompanies({page:1, size:1000}, org, filters, sort);
          setListCompanies(response?.data?.data);
          blockUI.current.open(false);
        } catch (error) {
          blockUI.current.open(false);
          AppHelper.checkError(error, snackbarUI);
        }
    }

    const handleChangeTypeText = (e) => {
        setInitialValues((prev) => ({...prev, [e.target.name]: e.target.value}))
    }

    const handleChangeEmployer = debounce((query) => {
        const org = initialValues.organization;
        if(!org)return;
        if(query?.length > 2)getAllCompanies(org, {name: query});
        if(!query)getAllCompanies(org);
    }, 500);

    useEffect(() => {
        getOrganization();
    }, [])

    const handleCloseFilters = () => {
        setFilterModal({});
        setValueCompany();
        setInputValue('')
        formik.resetForm();
        setInitialValues({organization: '', nameEmployer: ''});
        getRecordsList({filters:{}});
    }
    
  return (
    <Grid sx={style.containerFilterCompany}>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={style.form}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3} lg={2} >
                  <FormControl fullWidth>
                    <Typography sx={style.label} style={{marginBottom: 10}}>Organization</Typography>
                    <Select
                      value={initialValues.organization || ''}
                      id='organization'
                      name='organization'
                      onChange={(e)=> {
                        handleChangeTypeText(e);
                        setInputValue('')
                        setValueCompany();
                        getAllCompanies(e.target.value);
                      }}
                      size="small"
                      variant="outlined"
                      sx={{height: '40px'}}
                    >
                      {React.Children.toArray(
                        organizationList &&
                          organizationList?.map((item, index) => (
                            <MenuItem
                              value={item.id}
                              key={index}
                            >
                              {item.name}
                            </MenuItem>
                          ))
                      )}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={3} lg={2} >
                  <FormControl fullWidth>
                      <Typography style={{marginBottom: 10}} sx={style.label}>
                        Employer Name
                      </Typography>
                      <Autocomplete
                        id="combo-box-demo"
                        freeSolo
                        value={valueCompany}
                        onChange={(event, newValue) => { setValueCompany(newValue) }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {setInputValue(newInputValue)}}
                        name='nameEmployer'
                        options={listCompanies.map((option) => option.name)}
                        sx={{ width: '100%'}}
                        renderInput={(params) => <TextField
                            {...params}
                            label=""
                            size='small'
                            onChange={(e) => handleChangeEmployer(e.target.value)}
                            InputProps={{
                              ...params.InputProps,
                              type: 'search',
                            }}
                          />
                        }
                      />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={2} lg={1} >
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '20px',
                            alignItems: 'end',
                            justifyContent: 'center',
                            height: '100%',
                        }}
                    >
                        <Tooltip title="Clear filter">
                            <IconButton
                                onClick={handleCloseFilters}
                                sx={{width: '40px', height: '40px'}}
                            >
                                <ClearAllIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Clear filter">
                            <IconButton
                                type='submit'
                                sx={{width: '40px', height: '40px'}}
                            >
                                <SearchRoundedIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Grid>
              </Grid>
          </Box>
        </form>
    </Grid>
  )
}

export default FilterCompany