// @vendors
import React, { useContext, useMemo } from 'react'
import { Box, Button, Grid } from '@mui/material'
import { useSelector } from 'react-redux';

// @assets
import { GeneralStyles } from '../../../assets/css/general-style';

// @helpers
import { LISTROLES } from '../../../helpers/ListRoles';
import AppHelper from '../../../helpers/AppHelper';

// @services
import ManualService from '../../../services/newApi/ManualService';

// @context
import { useUI } from '../../../app/context/ui';
import { ManualContext } from '../Manuals';

const ToolbarManual = () => {
  const generalStyle = useMemo(() => GeneralStyles(), []);
  const { blockUI, snackbarUI } = useUI();
  const user = useSelector((state) => state.user);
  const manualService = useMemo(() => new ManualService(), []);
  const {getListManuals} = useContext(ManualContext);

  const addManual = (e) => {
      if (e.target.files[0]) {
          const originalFile = e.target.files[0];
          const formdata = new FormData();
          formdata.append('Name', originalFile?.name);
          formdata.append('File', originalFile);
          return sendData(e, formdata);
      }
      return;
  }

  const sendData = async (e, file) => {
      e.target.value = "";
      try {
          blockUI.current.open(true);
          await manualService.addManual(file);
          getListManuals();
      } catch (error) {
          blockUI.current.open(false);
          AppHelper.checkError(error, snackbarUI);
      }
  }
      
  return (
    <Box sx={generalStyle.containerToolbar}>
          <Grid sx={generalStyle.gridToolbar}>
            <Grid><h2>Manuals list</h2> </Grid>

            {user?.role === LISTROLES.superAdmin &&
              <Grid>
                <label htmlFor="contained-button-file-manuals">
                    <input
                        type='file'
                        accept=".pdf"
                        id="contained-button-file-manuals"
                        style={{display: 'none'}}
                        onChange={(e) => addManual(e)}
                    />
                    <Button
                        variant='outlined'
                        component="span"
                        sx={generalStyle.button}
                    >
                        Add New Manual
                    </Button>
                </label>
              </Grid>
            }
          </Grid>
    </Box>
  )
}

export default ToolbarManual