// @vendors
import React, { useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Tab,
  Tabs,
  Toolbar,
} from '@mui/material';

// @assets
import { PayrollStyle } from '../../../../assets/css/payroll-style';
import excelIcon from '../../../../assets/images/excel.svg';

// @components
import ExportToExcel from './ExportToExcel';

// @context
import { payrollContext } from '../../payrollView';
import { useUI } from '../../../../app/context/ui';

// @helpers
import AppHelper from '../../../../helpers/AppHelper';
import { downloadTemplate } from '../../../../helpers/FunctionsUtils';

// @services
import PayrollService from '../../../../services/newApi/PayrollService';

const ToolbarPayroll = () => {
  const style = useMemo(() => PayrollStyle(), []);
  const payrollService = useMemo(() => new PayrollService(), []);
  const state = useSelector((state) => state);
  const organizationId = state?.organizationReducer?.organizationSelected?.id;
  const {currentTab, setCurrentTab} = useContext(payrollContext);
  const [anchorElExcel, setAnchorElExcel] = useState(null);
  const { blockUI, snackbarUI } = useUI();

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const downloadExcel = async () => {
    try {
      blockUI.current.open(true);
      const response = await payrollService.donwloadExcelForInvoice(organizationId);
      if(!response?.data)return emptyData()
      blockUI.current.open(false);
      return downloadTemplate(response?.data, 'payroll_employers_invoice_summary.csv');
    } catch (error) {
      blockUI.current.open(false);
      AppHelper.checkError(error, snackbarUI);
    }
  }

  const emptyData = () => {
    AppHelper.showCustomMessage('No data found for download', snackbarUI);
    blockUI.current.open(false);
  }

  const handleClickExportExcel = (event) => {
    if(currentTab === 3)return downloadExcel();
    setAnchorElExcel(event.currentTarget);
  };

  const handleCloseModal = () => {
    setAnchorElExcel(null);
  };

  return (
    <Toolbar sx={{width: '100%'}}>
      <Box sx={style.containerToolbar}>
        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          textColor="inherit"
          indicatorColor="string"
          aria-label="secondary tabs example"
          sx={style.toolbar}
        >
          <Tab value={1} label="Active" sx={style.toolbarItem}/>
          <Tab value={2} label="Maxed Out" sx={style.toolbarItem}/>
          <Tab value={3} label="Invoice Summary" sx={style.toolbarItem}/>
        </Tabs>
        <Box sx={style.contSearchAndExcel}>
          <Button
            variant="contained"
            startIcon={<img src={excelIcon} alt='Buttom for download excel' style={{width: '16px'}}/>}
            sx={style.btnExcel}
            onClick={handleClickExportExcel}
          >
            Export to Excel
          </Button>
        </Box>

        <ExportToExcel
          anchorEl={anchorElExcel}
          handleClose={handleCloseModal}
          />
      </Box>
    </Toolbar>
  )
}

export default ToolbarPayroll