import { theme } from "../theme";

export const Styles = () => ({
  container: {
    padding: { xs: '0 24px 16px', md: '0 0 64px' },
  },
  titles: {
    margin: { xs: '18px 0', md: '48px 0' },
    '& h3': {
      padding: { xs: '0', md: '0 112px' },
      marginBottom: { xs: '12px', md: '24px' },
      fontSize: { xs: '1.125rem', md: '3rem' },
      fontWeight: 700,
    },
    '& p': {
      padding: { xs: '0', md: '0 112px' },
      fontSize: { xs: '1.125rem', md: '1.75rem' },
    },
  },
  toggleGroup: {
    display: { xs: 'flex', md: 'grid' },
    flexDirection: { xs: 'row', md: 'column' },
    flexWrap: { xs: 'wrap', md: 'nowrap' },
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: { xs: '16px 18px', md: '35px 27px' },
    padding: '0',
    '& .MuiToggleButtonGroup-grouped': {
      border: 'none',
      boxShadow: 4,
      borderRadius: '4px',
      '&:not(:first-of-type)': {
        borderRadius: '4px',
        border: 'none',
      },
      '&:not(:last-of-type)': {
        borderRadius: '4px',
        border: 'none',
      },
      '&.Mui-selected+.MuiToggleButtonGroup-grouped.Mui-selected': {
        border: 'none',
      },
    },
  },
  toggleGroupVocational: {
    display: { xs: 'flex', md: 'flex' },
    flexDirection: { xs: 'row', md: 'row' },
    flexWrap: { xs: 'wrap', md: 'wrap' },
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: { xs: '16px 18px', md: '35px 27px' },
    padding: '0',
    '& .MuiToggleButtonGroup-grouped': {
      border: 'none',
      boxShadow: 4,
      borderRadius: '4px',
      '&:not(:first-of-type)': {
        borderRadius: '4px',
        border: 'none',
      },
      '&:not(:last-of-type)': {
        borderRadius: '4px',
        border: 'none',
      },
      '&.Mui-selected+.MuiToggleButtonGroup-grouped.Mui-selected': {
        border: 'none',
      },
    },
  },
  toggle: {
    width: { xs: '47%', md: 'unset' },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    fontSize: '1.5rem',
    textTransform: 'none',
    //padding: { xs: '0 12px', md: '0 32px' },
    padding: { xs: '10px 12px', md: '20px 32px' },
    //height: { xs: '150px', md: '200px', lg: '250px' },
    minHeight: { xs: '150px', md: '200px', lg: '250px' },
    backgroundColor: '#FFF',
    color: '#000',
    borderRadius: '4px',
    border: 'none',
    '& p': {
      fontWeight: '700',
      fontSize: { xs: '0.875rem', md: '1.25rem' },
    },
    '&:hover': {
      border: 'none',
      backgroundColor: 'rgba(25, 59, 103, 0.05)',
    },
    '&.Mui-selected': {
      border: 'none',
      backgroundColor: '#81bc56',
      color: '#FFF',
      '& img': {
        filter: 'invert(1)',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#81bc56',
      border: 'none',
      color: '#FFF',
      '& img': {
        filter: 'invert(1)',
      },
    },
    '& img': {
      height: { xs: '45px', md: '80px' },
      width: { xs: '45px', md: '80px' },
      fontSize: { xs: '32px', md: '42.44px' },
    },
    '&.Mui-disabled': {
      '& img': {
        filter: 'opacity(10%)',
      },
    },
  },
  toggleVocational: {
    width: { xs: '47%', md: '45%' },
    boxSizing: 'border-box',
    justifyContent: 'space-evenly',
    fontSize: '1.5rem',
    textTransform: 'none',
    padding: { xs: '0 12px', md: '0 32px' },
    height: '250px',
    backgroundColor: '#FFF',
    color: '#000',
    borderRadius: '4px',
    border: 'none',
    '& p': {
      fontWeight: '700',
      fontSize: { xs: '1rem', md: '1.25rem' },
    },
    '&:hover': {
      border: 'none',
      backgroundColor: 'rgba(25, 59, 103, 0.05)',
    },
    '&.Mui-selected': {
      border: 'none',
      backgroundColor: '#81bc56',
      color: '#FFF',
      '& img': {
        filter: 'invert(1)',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#81bc56',
      border: 'none',
      color: '#FFF',
      '& img': {
        filter: 'invert(1)',
      },
    },
    '& img': {
      height: { xs: '95px', md: '125px' },
      width: { xs: '95px', md: '125px' },
      fontSize: { xs: '32px', md: '42.44px' },
    },
    '&.Mui-disabled': {
      '& img': {
        filter: 'opacity(10%)',
      },
    },
  },
  logo: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: 'space-around',
    marginTop: { xs: '12px', md: '32px' },
    padding: { xs: '0', md: '0 32px' },
    '& .MuiButton-root': {
      height: '52px',
      width: { xs: '100%', md: '40%' },
      marginBottom: { xs: '16px', md: '0' },
      textTransform: 'none',
      fontSize: { xs: '0.875rem', md: '1.5rem' },
    },
  },
  cancelBtn: {
    color: '#101010',
    border: '1px solid #101010',
    marginTop: { xs: '24px', md: '0' },
    '&:hover': {
      border: '1px solid #101010',
    },
  },
  sendButton: {
    color: '#FFF',
    border: '1px solid #101010',
    backgroundColor: '#101010',
    '&:hover': {
      backgroundColor: '#101010',
    },
  },
  dialog: {
    maxWidth: 'sm',
    title: {
      fontSize: '2rem',
      fontWeight: '700',
      color: '#101010',
    },
    content: {
      fontSize: '1.5rem',
      color: '#101010',
    },
    dialogBox: {
      '& .MuiDialog-paper': {
        padding: '48px',
      },
    },
    cancelButton: 'rgba(36, 35, 35, 0.5)',
    ctaButton: '#101010',
    showButton: 'none',
  },
  containerUnsubscribed:{
    width: '100%',
    backgroundColor: 'rgb(249, 249, 249)',
    marginTop: '50px',
    padding: '40px',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    borderTop: '2px solid #1676F3',
    borderRadius: '4px',
  },
  textUnsubscribed: {
    fontSize: "28px",
    fontWeight: '700',
    color: '#101010',
    margin: '0 0 30px 0',
    display: 'flex',
    textAlign: 'center'
  }
});

export const MultiQuestStyles = () => ({
  toolbar: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    color: '#000',
  },
  appBar: {
    height: { xs: '80px', md: '85px' },
    padding: { xs: '0 16px', xl: '0 192px' },
    width: '100%',
    justifyContent: 'center',
    backgroundColor: '#fff',
    boxShadow: 'none',
    position: 'unset',
  },
  logo: {
    width: { xs: '72px', sm: '210px', md: '315px' },
    height: '100%',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    margin: { xs: '40px 0', md: '80px 0' },
    padding: { xs: '48px 24px', md: '96px 128px' },
    justifyContent: 'center',
    alignItems: 'center',
  },
  topBar: {
    width: '100%',
    textAlign: 'center',
    '& p': {
      textTransform: 'uppercase',
      fontSize: '1.25rem',
      color: theme.palette.primary.main,
      marginBottom: '30px',
      [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
      },
    },
  },
  progress: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    '& .MuiLinearProgress-root': {
      width: '100%',
      height: '5px',
      backgroundColor: 'rgba(36, 35, 35, 0.3)',
      borderRadius: '4px',
      '& 	.MuiLinearProgress-bar': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '30px',
    width: '100%',
    '& .multiTitle, & .title': {
      fontSize: '1.125rem',
      margin: '24px 0 0',
      [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
      },
    },
    '& .MuiTextField-root': {
      height: '55px',
      width: '100%',
      margin: '18px 0',
      position: 'relative',
      '& .MuiOutlinedInput-root': {
        height: '100%',
        '& .MuiOutlinedInput-input': {
          height: '100%',
          padding: '0 16px',
        },
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
            borderWidth: '2px',
          },
        },
      },
      '& .MuiInputLabel-shrink': {
        padding: '0 8px',
        background: '#FFF',
        color: theme.palette.primary.main,
      },
      '& .MuiFormHelperText-root': {
        position: 'absolute',
        bottom: '-24px',
      },
      [theme.breakpoints.down('md')]: {
        '& .MuiOutlinedInput-input': {
          padding: '0 8px',
        },
      },
    },
    '& .MuiButton-root': {
      marginTop: '22px',
      width: '100%',
      height: '48px',
      backgroundColor: '#101010',
      color: '#FFF',
      textTransform: 'capitalize',
      fontSize: '1.125rem',
      '&:hover': {
        xs: { backgroundColor: '#101010', color: '#FFF' },
        md: {
          backgroundColor: '#f6f6f6',
          color: '#101010',
        },
      },
    },
  },

  numerical: {
    width: '100%',
  },
  showText: {
    position: 'absolute',
    right: { xs: 'calc(15% - 20px)', md: 'calc(5% - 20px)' },
    top: 'calc(50% - 20px)',
  },
  buttonWrapper: {
    marginTop: '22px',
    width: '100%',
    '& .MuiButton-root': {
      marginTop: '22px',
      width: '100%',
      height: '48px',
      backgroundColor: '#101010',
      color: '#FFF',
      textTransform: 'capitalize',
      fontSize: '1.125rem',
      '&:hover': {
        xs: { backgroundColor: '#101010', color: '#FFF' },
        md: {
          backgroundColor: '#f6f6f6',
          color: '#101010',
        },
      },
    },
  },

  tip: {
    color: theme.palette.primary.main,
    marginTop: '-0.2em',
  },
});

export const CheckboxStyles = () => ({
  checkboxCtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    '& .MuiFormControlLabel-root': {
      '& .MuiFormControlLabel-label': {
        fontSize: '1.25rem',
        [theme.breakpoints.down('md')]: {
          fontSize: '1rem',
        },
      },
    },
  },
  checkbox: {
    '&.MuiCheckbox-root': {
      color: theme.palette.primary.main,
    },
  },
  formHelperText: {
    textAlign: 'center',
    width: '100%',
  },
  tip: {
    color: theme.palette.primary.main,
    marginTop: '-0.2em',
  },
});

export const SingleListStyles = () => ({
  selectCtn: {
    margin: '16px 0',
  },
  stateError: {
    position: 'absolute',
    bottom: '-10px',
    left: '15px',
    background: '#FFF',
    color: '#d32f2f',
    padding: '0 8px',
    fontSize: '0.875rem !important',
    '&::first-letter': {
      textTransform: 'capitalize',
    },
  },
});

export const QustionVeteranStyles = () => ({
  paperVeterean:{
    display: 'flex',
    flexDirection: 'column',
    margin: { xs: '40px 0', md: '80px 0' },
    padding: { xs: '48px 24px', md: '96px 128px' },
    justifyContent: 'center',
    alignItems: 'center',
  },
  topBoxVeteran:{
    width: '100%',
    textAlign: 'center',
    '& p': {
      textTransform: 'uppercase',
      fontSize: '1.25rem',
      color: theme.palette.primary.main,
      marginBottom: '30px',
      [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
      },
    },
  },
  greatTilte: {       
    fontSize: "2.5rem",
    fontWeight: "700",
    color: "#101010",
    [theme.breakpoints.down("md")]: {
        fontSize: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: "1.5rem",
    },
  },
  subText:{
    fontSize: "1.125rem",
    color: "#101010",
    [theme.breakpoints.down("md")]: {
    fontSize: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
    fontSize: "0.75rem",
    },
  }
});

export const ThankyouStyles = () => ({
  containerThanks:{
    position: 'fixed',
    left: '0',
    display: 'flex',
    height: '84vh',
    width: '100vw',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgb(249, 249, 249)',
  },
  paperThanks: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: { xs: '20px 16px', md: '30px 48px' },
    width: { xs: '90%', md: '75%', lg: '50%' },
  },
  containerText: {
    marginLeft: { xs: '5%', md: '10%', lg: '15%'},
    display: "flex",
    flexDirection: "column", 
    gap: { xs: '0.5rem', md: '2rem'},
  },
  emptyContainer: {
    position: 'fixed',
    left: '0',
    top: '92vh',
    height: '8vh',
    width: '100vw',
    background: '#000000',
  },
  textGreat: {
    fontSize: "2.8rem",
    fontWeight: "700",
    color: "#101010",
    [theme.breakpoints.down("md")]: {
      fontSize: "2.4rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.2rem",
    },
  },
  textStatus: {
    fontSize: "1.325rem",
    color: "#101010",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
  },
});
