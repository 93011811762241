// @vendors
import React, { useMemo, useState } from 'react'
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { isNull as _isNull } from "lodash";

// @components
import { AppTextField } from '../../../components/forms';

// @helpers
import { MenuItemProps } from '../../../helpers/FunctionsUtils';

// @assets
import { UserManagementStyle } from '../../../assets/css/employeeTable-style';
import { CreateEmployeeStyles } from '../../../assets/css/createEmployee-style';
import { StyleAPiClientDialog } from '../../ApiClient/StyleApiClient';

// @constants
import { ROUTENAME } from '../../../navigation/RouteName';

const CreateTicket = () => {
  const id = '';
  const history = useHistory();
  const style = useMemo(() => UserManagementStyle(), []);
  const styleForm = useMemo(() => CreateEmployeeStyles(), []);
  const styles = useMemo(() => StyleAPiClientDialog(), []);
  const listPriority = ['Low priority', 'Medium priority', 'High priority'];
  const [screenshotName, setScreenshotName] = useState('');

  const initialValues = {
    priority: '',
    jiraLink: '',
    locationIssue: '',
    functionImpact: '',
    clientName: '',
    employeeName: '',
    employeeEmail: '',
    dataFieldAffect: '',
    description: '',
  }
  const [formValues, setFormValues] = useState(initialValues);

  const validationSchema = Yup.object({
    locationIssue: Yup.string('Enter your location')
      .required('Location is required')
      .nullable()
      .typeError(`Location can't be empty`),
    jiraLink: Yup.string('Enter your link')
      .notRequired()
      .nullable()
      .typeError(`Link can't be empty`),
    functionImpact: Yup.string('Enter your text')
      .notRequired()
      .nullable()
      .typeError(`Function can't be empty`),
    priority: Yup.string('Select your priority')
      .nullable()
      .required('Priority is required')
      .typeError("Priority can't be empty"),
    clientName: Yup.string("Enter the client's name")
      .notRequired()
      .nullable()
      .typeError(`Name can't be empty`),
    employeeName: Yup.string("Enter the employee's name")
      .notRequired()
      .nullable()
      .typeError(`Name can't be empty`),
    employeeEmail: Yup.string('Enter the email')
      .email('Invalid email')
      .max(50, 'Max. 50 characters')
      .notRequired()
      .typeError(`Email can't be empty`),
    dataFieldAffect: Yup.string("Enter the data")
      .notRequired()
      .nullable()
      .typeError(`Data can't be empty`),
    description: Yup.string("Enter your description")
      .required('Description is required')
      .nullable()
      .typeError(`Description can't be empty`),
    
  });

  const onSubmit = (values) => {
    //const OBJ = { };
    console.log(values)
  }

  const handleScreen = (e) => {
    const file = e.target.files[0];
    if(_isNull(file.name))return e.target.value = "";
    setScreenshotName(file?.name ?? '');
    console.log(file)
    e.target.value = "";
  }

  return (
    <Grid sx={style.container}>
    <Grid sx={style.containerBody}>
      <Typography sx={style.title}>{id ? 'Edit ticket' : 'Create ticket'}</Typography>
      
      <Grid >
        <Formik
          initialValues={formValues}
          validationSchema={validationSchema}
          onSubmit={(values) => { onSubmit(values) }}
          enableReinitialize={true}
        >
          {(props) => {
            const {
              touched,
              errors,
              handleBlur,
              handleChange,
              values,
            } = props;
            return (
                <Form autoComplete="off" className="formCreateUser">
                    <Box sx={{...styleForm.inputsCtn, gridGap: '10px',}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                    <p>Location issue: <span style={{color: "red"}}>*</span> </p>
                                    <AppTextField
                                        sx={styleForm.input}
                                        label="Type your location here"
                                        variant="outlined"
                                        id="locationIssue"
                                        name="locationIssue"
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                    <p>Jira link:</p>
                                    <AppTextField
                                        sx={styleForm.input}
                                        label="Type your link here"
                                        variant="outlined"
                                        id="jiraLink"
                                        name="jiraLink"
                                      />
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                    <p>Function impact:</p>
                                    <AppTextField
                                        sx={styleForm.input}
                                        label="Type your text here"
                                        variant="outlined"
                                        id="functionImpact"
                                        name="functionImpact"
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                    <p>Priority: <span style={{color: "red"}}>*</span> </p>
                                    <Select
                                        id="priority"
                                        name='priority'
                                        value={values.priority || ''}
                                        sx={{
                                          ...styles.fieldsInput,
                                          height: '56px',
                                          '& .MuiOutlinedInput-notchedOutline': {
                                            border: '1px solid #101010',
                                          },
                                        }}
                                        onChange={(e) => {handleChange(e);}}
                                        onBlur={handleBlur}
                                        MenuProps={MenuItemProps}
                                        placeholder={'-- Select --'}
                                        error={touched.priority && Boolean(errors.priority)}
                                    >
                                        {listPriority?.map((item, index) => (
                                          <MenuItem
                                            value={item}
                                            key={index}
                                          >
                                            {item}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                      {errors.priority && touched.priority && (
                                        <Typography style={{color: '#D32F2F', fontSize: '14px', marginLeft: '15px' }}>{errors.priority}</Typography>
                                      )}
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                    <p>Client name:</p>
                                    <AppTextField
                                        sx={styleForm.input}
                                        label="Type the client's name"
                                        variant="outlined"
                                        id="clientName"
                                        name="clientName"
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                    <p>Employee name:</p>
                                    <AppTextField
                                        sx={styleForm.input}
                                        label="Type the employee's name"
                                        variant="outlined"
                                        id="employeeName"
                                        name="employeeName"
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                    <p>Employee email:</p>
                                    <AppTextField
                                        sx={styleForm.input}
                                        label="Type your email here"
                                        variant="outlined"
                                        id="employeeEmail"
                                        name="employeeEmail"
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                    <p>Data field affect:</p>
                                    <AppTextField
                                        sx={styleForm.input}
                                        label="Type your data here"
                                        variant="outlined"
                                        id="dataFieldAffect"
                                        name="dataFieldAffect"
                                    />
                                </Box>
                            </Grid>
                            
                            <Grid item xs={12} md={12}>
                              <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                <p>Description: <span style={{color: "red"}}>*</span></p>
                                <AppTextField
                                    sx={{...styleForm.input}}
                                    label="Type your description here"
                                    variant="outlined"
                                    id="description"
                                    name="description"
                                    multiline
                                />
                              </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <Box sx={{...styleForm.boxItem, marginBottom: '5px'}}>
                                <p>Screenshot:</p>
                                <input
                                    type='file'
                                    accept="image/*"
                                    id="file-screen-ticket"
                                    style={{display: 'none'}}
                                    onChange={(e) => handleScreen(e)}
                                />
                                <Box sx={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                  <label htmlFor="file-screen-ticket">
                                    <IconButton
                                      aria-label="add to camera"
                                      sx={{color: '#388e3c'}}
                                      size="large"
                                      component="span"
                                    >
                                      <AddAPhotoIcon />
                                    </IconButton>
                                  </label>
                                  {screenshotName && <Typography sx={{fontSize: '14px'}}>{screenshotName}</Typography>}
                                </Box>
                              </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={styleForm.formButtonWrapper}>
                        <Button
                            color="inherit"
                            onClick={() => history.push(ROUTENAME.ticket)}
                        >
                            Cancel
                        </Button>
                        <Button type="submit">{'Create'}</Button>
                    </Box>
                </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  </Grid>
  )
}

export default CreateTicket