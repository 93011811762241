import { theme } from "../theme";

export const toolbarStyles = () => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '20px 2%',
    padding: '30px 0px 30px 30px',
    backgroundColor: '#FFF',
    height: 'auto',
    margin: '0',
    borderRadius: '4px 4px 0 0',
    borderBottom: '1px solid rgba(224,224,224,1)',
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      gap: '12px 2%',
      justifyContent: 'flex-start',
      padding: '16px 16px',
      "& h2": {
        width: '20%',
        textAlign: 'start',
        fontSize: '1.25rem',
      },
    },

    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      gap: '12px 2%',
      justifyContent: 'center',
      padding: '16px 16px',
      "& h2": {
        width: '100%',
        textAlign: 'start',
        fontSize: '1.25rem',
      },
    },
  },
  filterButton: {
    textTransform: 'capitalize',
    height: '56px',
    width: '200px',
    fontSize: '1.5rem',
    border: `1px solid #101010`,
    color: '#101010',
    flexDirection: 'row-reverse',
    justifyContent: 'space-around',
    '& span': {
      margin: '0',
      '& .MuiSvgIcon-root': {
        fontSize: '2rem',
      },
    },
    ':hover': {
      background: 'rgba(0,0,0,0.1)',
    },
    [theme.breakpoints.down('md')]: {
      width: '15%',
      height: '40px',
      fontSize: '1rem',
      '& span': {
        '& .MuiSvgIcon-root': {
          fontSize: '1.5rem',
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '29%',
    },
  },
  searchBox: {
    width: '375px',
    '& .MuiOutlinedInput-root': {
      fontSize: '1.25rem',
      padding: '0 12px',
      height: '56px',
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '25%',
      '& .MuiOutlinedInput-root': {
        fontSize: '1rem',
        padding: '0 12px',
        height: '40px',
        width: '100%',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '69%',
    },
  },
  searchBoxSm: {
    [theme.breakpoints.down('md')]: {
      width: '50%'
    },
  },
  gridToolbarField: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    flex: 1,
    paddingLeft: '30px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: '0px',
      justifyContent: 'center'
    },
  },
  btnAddUser: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px'
    },
  }
});
