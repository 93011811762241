// @vendors
import React, { useMemo, useState } from 'react'
import sx from "mui-sx";
import { useHistory } from 'react-router-dom';

// @components material
import {
    Box,
    Button,
    Collapse,
    EditIcon,
    ExpandMoreIcon,
    Grid,
    IconButton,
    MenuItem,
    Select,
    TableCell,
    TableRow,
    Tooltip,
} from '../../../components/shared/MaterialUI';
import EditNoteIcon from '@mui/icons-material/EditNote';

// @assets
import { UserManagementStyle } from '../../../assets/css/employeeTable-style';
import { GeneralStyles } from '../../../assets/css/general-style';

// @components
import TicketDetail from './TicketDetail';
import ResponseDialog from './ResponseDialog';

// @constants
import { ROUTENAME } from '../../../navigation/RouteName';
import { MenuItemProps } from '../../../helpers/FunctionsUtils';

const TicketRow = ({row}) => {
    const generalStyle = useMemo(() => GeneralStyles(), []);
    const style = useMemo(() => UserManagementStyle(), []);
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const history = useHistory();
    const listStatus = ['Processing', 'Resolved', 'Open'];

    const handleOpen = async () => {setOpen(!open);}

    const bgColorChip = () => {
        if(row?.status === 'Open')return '1px solid #d32f2f';
        if(row?.status === 'Processing')return '1px solid #f57c00';
        return '1px solid #388e3c';
    }

    const handleClickOpen = () => setOpenModal(!openModal);

    const handleChange = (e) => {
        const value = e.target.value;
        console.log(value)
    }

  return (
    <>
        <TableRow>
            <TableCell
                scope="row"
                sx={sx(
                    generalStyle.tableCell,
                    { condition: open, sx: generalStyle.borderNone },
                    { condition: !open, sx: generalStyle.bottomBorder }
                )}
            >
                <IconButton onClick={handleOpen}>
                    <ExpandMoreIcon />
                </IconButton>
            </TableCell>

            <TableCell
                scope="row"
                sx={{...generalStyle.tableCell, textAlign: 'left', paddingLeft: '10px'}}
            >
                {row?.name}
            </TableCell>
            
            <TableCell
                scope="row"
                sx={{
                    ...generalStyle.tableCell,
                    ...generalStyle.tableColumnHide,
                    textAlign: 'left'
                }}
            >
                {row?.createDate}
            </TableCell>

            <TableCell
                scope="row"
                sx={{
                    ...generalStyle.tableCell,
                    ...generalStyle.tableColumnHide,
                    textAlign: 'left',
                }}
            >
                {row?.priority}
            </TableCell>
            
            <TableCell
                scope="row"
                sx={{...generalStyle.tableCell, textAlign: 'left'}}
            >
                {/* <Chip
                    label={row?.status}
                    color={bgColorChip()}
                    variant="outlined"
                /> */}
                <Box>
                    <Select
                        id="priority"
                        name='priority'
                        value={row?.status}
                        sx={{
                            width: '130px',
                            height: '40px',
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: bgColorChip(),
                            },
                        }}
                        onChange={(e) => {handleChange(e);}}
                        MenuProps={MenuItemProps}
                    >
                        {listStatus?.map((item, index) => (
                            <MenuItem
                                value={item}
                                key={index}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </TableCell>

            <TableCell
                sx={{
                    ...generalStyle.tableCell,
                    ...generalStyle.tableColumnHide
                }}
            >
            <Grid sx={style.containerButtons}>
                <Tooltip
                    title={<p style={{ fontSize: "16px", margin: "0" }}>Response from devs</p>}
                    arrow
                >
                    <Button
                        variant="contained"
                        onClick={handleClickOpen}
                        sx={style.btnEdit}
                        children={<EditNoteIcon sx={{fontSize: "2rem"}} />}
                    />
                </Tooltip>
                <Tooltip
                    title={<p style={{ fontSize: "16px", margin: "0" }}>Edit ticket</p>}
                    arrow
                >
                    <Button
                        variant="contained"
                        onClick={() => history.push(`${ROUTENAME.editTicket}${row?.id}`)}
                        sx={style.btnEdit}
                        children={<EditIcon sx={{fontSize: "1.8rem"}} />}
                    />
                </Tooltip>
            </Grid>
            </TableCell>      
        </TableRow>

        <TableRow>
            <TableCell
                sx={sx(
                    generalStyle.tableCell,
                    { padding: "0"},
                    { condition: !open, sx: generalStyle.borderNone },
                    { condition: open, sx: generalStyle.bottomBorder }
                )}
                colSpan={12}
            >
                <Collapse
                    in={open}
                    timeout="auto"
                    unmountOnExit
                    sx={{ width: '100%' }}
                >
                    <TicketDetail row={row} />
                </Collapse>
            </TableCell>
        </TableRow>
        <ResponseDialog open={openModal} handleCloseModal={handleClickOpen} />
    </>
  )
}

export default TicketRow