// @vendors
import React, { createContext, useEffect, useMemo, useState } from 'react'
import {
  Container,
  Grid,
} from '@mui/material';

// @assets
import { GeneralStyles } from '../../assets/css/general-style';

// @components
import Pageheader from '../../components/PageHeader';
import UserTable from './components/UserTable';

// @services
import UserServiceNewApi from '../../services/newApi/UserServiceNewApi';

import { useUI } from '../../app/context/ui';
import AppHelper from '../../helpers/AppHelper';

const UserManagement = () => {
  const generalStyle = useMemo(() => GeneralStyles(), []);
  const userService = useMemo(() => new UserServiceNewApi(), []);
  const { blockUI, snackbarUI } = useUI();

  const [rowsState, setRowsState] = useState({
    pageSize: 20,
    rows: [],
    rowCount: 0,
    page: 1,
  });

  const [pages, setPages] = useState({
    page: 0,
    size: 10
  });

  const [search, setSearch] = useState({
    fullName: ''
  });

  const getUsers = async (page=1, size=pages.size, filters=search) => {
    try {
      blockUI.current?.open(true);
      const response = await userService.getAllUsers(page, size, filters)
      successCallUsers(response?.data)
      blockUI.current?.open(false);
    } catch (e) {
      blockUI.current?.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }
  
  const successCallUsers = (data) => {
    setPages((prev) => ({
      ...prev,
      page: data?.pagination?.page_number,
      size: data?.pagination?.page_size
      ,
    }));
    setRowsState((prev) => ({
      ...prev,
      pageSize: data?.pagination?.page_size ?? 10,
      rows: data?.data,
      rowCount: data?.pagination?.total_records,
      page: data?.pagination.page_number - 1,
    }));
  }
  
  useEffect(() => {
    getUsers()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container
      component="section"
      maxWidth="xl"
      sx={generalStyle.dashCtn}
    >
      <Container
        component="section"
        maxWidth="xl"
        sx={generalStyle.containerTitle}
      >
        <Pageheader
          title={'User management'}
          subtitle={'Welcome to user management dashboard'}
        />
      </Container>

      <UserContext.Provider value={{
          rowsState,
          pages,
          setRowsState,
          setPages,
          getUsers,
          search,
          setSearch
        }}
      >
        <Grid>
          <UserTable/>
        </Grid>
      </UserContext.Provider>
    </Container>
  )
}

export const UserContext = createContext({});
export default UserManagement