// @vendors
import React, { useMemo } from 'react'

// @material-ui
import {
    Box,
    Dialog,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "../../../components/shared/MaterialUI";

// @assets
import { EditPayrollStyle, PayrollStyle } from '../../../assets/css/payroll-style';

// @components
import FormEditHours from './FormEditHours';
import FormEditDetail from './FormEditDetail';

const EditItemPayroll = ({open, handleClose, data}) => {
    const style = useMemo(() => EditPayrollStyle(), []);
    const styleTable = useMemo(() => PayrollStyle(), []);

  return (
    <>
        <Dialog
            sx={style.dialogEdit}
            open={open}
            fullWidth={true}
            maxWidth={false}
        >
            <Box sx={style.body}>
                <Typography sx={style.title}>Edit</Typography>

                <Box sx={style.table}>
                    <Table aria-label="table" stickyHeader>
                        <TableHead sx={styleTable.tableRow}>
                            <TableRow sx={styleTable.tableRow}>
                                <TableCell sx={style.tableHeadTitleEdit}>Last Sync Date</TableCell>
                                <TableCell sx={style.tableHeadTitleEdit}>State Status</TableCell>
                                <TableCell sx={style.tableHeadTitleEdit}>Employee Full Name</TableCell>    
                                <TableCell sx={style.tableHeadTitleEdit}>Employeer</TableCell>
                                <TableCell sx={style.tableHeadTitleEdit}>Employer Rate (%)</TableCell>
                                <TableCell sx={style.tableHeadTitleEdit}>Target Group</TableCell>        
                                <TableCell sx={style.tableHeadTitleEdit}>Target Group Code</TableCell>      
                                <TableCell sx={style.tableHeadTitleEdit}>Start Date</TableCell> 
                                <TableCell sx={style.tableHeadTitleEdit}>Pay Schedule</TableCell>
                                <TableCell sx={style.tableHeadTitleEdit}>Current YTD Gross wages ($)</TableCell>
                                <TableCell sx={style.tableHeadTitleEdit}>Current YTD Hours</TableCell>
                                <TableCell sx={style.tableHeadTitleEdit}>WOTC Credit % Multiplier</TableCell>  
                                <TableCell sx={style.tableHeadTitleEdit}>Max Wage ($)</TableCell>
                                <TableCell sx={style.tableHeadTitleEdit}>Max Tax Credit ($)</TableCell>
                                <TableCell sx={style.tableHeadTitleEdit}>Tax Credit YTD ($)</TableCell>
                                <TableCell sx={style.tableHeadTitleEdit}>Invoice YTD ($)</TableCell>
                                <TableCell sx={style.tableHeadTitleEdit}>Actual Invoice ($)</TableCell>
                               
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <FormEditDetail data={data}/>   
                        </TableBody>
                    </Table>
                </Box>

                <Box sx={style.containerFormsHoursAndWage}>
                    <FormEditHours
                        handleClose={handleClose}
                        info={data}
                    />
                </Box>                
            </Box>
        </Dialog>
        
    </>
  )
}

export default EditItemPayroll