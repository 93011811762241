// @vendors
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom';

// @components material
import {
    AddIcon,
    Box,
    Button,
    Grid,
 } from '../../../components/shared/MaterialUI';

// @assets
import { GeneralStyles } from '../../../assets/css/general-style';

// @constants
import { ROUTENAME } from '../../../navigation/RouteName';

const ToolbarTicket = () => {
  const generalStyle = useMemo(() => GeneralStyles(), []);
  const history = useHistory();

  return (
    <Box sx={generalStyle.containerToolbar}>
      <Grid sx={generalStyle.gridToolbar}>
        <Grid><h2>Tickets</h2> </Grid>

        <Grid sx={generalStyle.btnAddUser}>
          <Button
            variant='outlined'
            endIcon={<AddIcon />}
            sx={generalStyle.button}
            onClick={() => history.push(ROUTENAME.createTicket)}
          >
            Add Ticket
          </Button>
        </Grid>  
      </Grid>
    </Box>
  )
}

export default ToolbarTicket