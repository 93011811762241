
import { theme } from "../theme";

export const HeaderStyles = (_) => ({
  toolbar: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    color: '#000',
  },
  appBar: {
    //height: { xs: '80px', md: '85px' },
    minHeight: '85px',
    maxHeight: '85px',
    padding: '0 16px',
    width: '100%',
    justifyContent: 'center',
    backgroundColor: '#fff',
    boxShadow: 'none',
    position: 'unset',
    [theme.breakpoints.down('sm')]: {
      minHeight: '85px',
      maxHeight: '125px',
    },
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    "& img": {
      [theme.breakpoints.down('sm')]: {
        width: '128px',
      },
    },
  },
  companyLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '10px',
    height: '150px',
    "& img": {
      borderRadius: '5px',
      objectFit: 'cover',
      marginTop: '20px',
      width: '200px',
      maxHeight: '150px',
      [theme.breakpoints.down('sm')]: {
        width: '128px',
      },
    },
  },
  organizationLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '70px',
    "& img": {
      borderRadius: '3px',
      objectFit: 'fill',
      width: '200px',
      maxHeight: '70px',
      [theme.breakpoints.down('sm')]: {
        width: '128px',
        maxHeight: '50px',
      },
    },
  },
  wrapperLogout: {
    display: 'flex',
    alignItems: 'center',
    color: '#101010',
    fontSize: '1rem',
  },
  dialog: {
    maxWidth: 'md',
    title: {
      fontSize: '2.5rem',
      fontWeight: '700',
      color: '#101010',
    },
    content: {
      fontSize: '1.5rem',
      color: '#101010',
    },
    dialogBox: {
      '& .MuiDialog-paper': {
        padding: '56px',
      },
    },
    cancelButton: 'rgba(36, 35, 35, 0.5)',
    ctaButton: '#101010',
  },
  containerImg:{
    display: "flex",
    justifyContent: "center",
    alignItems:"center",
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '5px'
    },
  }
});

export const PublicHeaderStyles = (theme) => ({
  appBar: {
    backgroundColor: '#FFF',
    height: { md: '85px', xs: '80px' },
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: { xl: '0 192px', md: '0 2rem', xs: '0 16px' },
  },
  toolbar: {
    justifyContent: { xs: 'space-between' },
    width: '100%',
  },
  mobileMenu: {
    display: { xs: 'flex', md: 'none' },
  },
  menuItems: {
    display: { xs: 'none', md: 'flex' },
    width: { md: '80%' },
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  wrapperLog: {
    display: 'flex',
    gap: '8px',
  },
  btnSignIn: {
    width: { lg: '192px', xs: '112px' },
    height: { lg: '56px', md: '48px', xs: '40px' },
    fontSize: '1.5rem',
    textTransform: 'none',
    backgroundColor: '#101010',
    color: '#FFF',
    '&:hover': { backgroundColor: 'rgba(240, 240, 240, 0.6)', color: '#101010' },
  },
});

export const TicketStyles = (_) => ({
  container: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '20px 80px',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      padding: '30px 40px'
    }
  },
  titleHeader: {
    display: 'flex',
    margin: '0 0 5px 0',
    textAlign: 'start',
    fontSize: '18px',
    fontWeight: '700'
  },
  subTitleHeaderHover: {
    '&:hover':{
        cursor: 'pointer',
        textDecoration: 'underline',
        color: '#66bb6a',
    }
  },
  subTitleHeader: {
    margin: 0,
    //display: 'flex',
    textAlign: 'start',
    fontSize: '15px',
    fontWeight: '400',
    //boxSizing: 'border-box',
    maxWidth: '100%',
    minHeight: '1%',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
  },
});

export const GeneralStyles = (_) => ({
  dashCtn: {
    color: '#101010',
    padding: '0 48px',
    [theme.breakpoints.down('md')]: {
      padding: '0px',
    },
  },
  button: {
    width: '100%',
    maxHeight: '64px',
    height: '56px',
    fontSize: '1.2rem',
    textTransform: 'none',
    backgroundColor: '#101010',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#101010',
    },
    '& .MuiButton-endIcon>*:nth-of-type(1)': {
      fontSize: '1.6rem',
    },
    [theme.breakpoints.down('md')]: {
      height: '40px',
      fontSize: '1rem',
      '& span': {
        '& .MuiSvgIcon-root': {
          fontSize: '1.5rem',
        },
      },
      "& .MuiOutlinedInput-root": {
        height: '40px',
      },
    }
  },
  //table
  tableCell: {
    fontSize: "1.3rem",
    textAlign: 'center'
  },
  tableColumnHide: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  bottomBorder: {
    borderBottom: "1px solid rgba(224,224,224,1)",
  },
  borderNone: {
    border: "none",
  },
  //dialog
  dialog: {
    dialogBox: {
      "& .MuiDialog-paper": {
        padding: "48px",
      },
    },
    title: {
      fontSize: "2rem",
      fontWeight: "700",
      color: "#101010",
    },
  },
  showPassword: {
    position: 'absolute',
    right: '16px',
    top: '7px'
  },
  stateError: {
    position: 'absolute',
    bottom: '-25px',
    left: '15px',
    background: '#FFF',
    color: '#d32f2f',
    padding: '0 8px',
    fontSize: '0.875rem !important',
    '&::first-letter': {
      textTransform: 'capitalize'
    }
  },
  btnAddUser: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px'
    },
  },
  //organization
  containerToolbar: {
    padding: "20px 28px",
    borderBottom: "1px solid rgba(224,224,224,1)",
  },
  gridToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
     flexDirection: 'column'
    }
  },
  containerTitle: {
    padding: '0 0px 48px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: '0 20px 48px 20px',
    }
  },
  containerFormOrganization: {
    padding: '20px',
    [theme.breakpoints.down('md')]: {
      padding: '0px',
    },
  },
  uploadProfilePic: {
    width: '150px',
    position: 'relative',
    height: '150px',
    borderRadius: '999px',
    border: '1px solid #fff',
    filter: 'drop-shadow(0px 0px 1px rgba(36, 55, 71, 0.119))',
    '& img': {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      objectFit: 'contain',
      objectPosition: '50% 50%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100px',
      height: '100px',
    }
  },
  iconBackground:{
    width: '100%',
    height: '100%',
    color: '#0202',
  },
  iconCamera:{
    background: '#101010',
    color: '#FFF',
    position: 'absolute',
    bottom: '0px',
    right: '0px',
    '&:hover': {
        background: '#101010',
        boxShadow: 4,
      },
  },
});
