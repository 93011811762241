// @vendors
import React, { useContext } from 'react'
import {
  Divider,
  Fade,
  Menu,
  MenuItem,
} from '@mui/material'
import { useSelector } from 'react-redux';

// @assets
import { MenuExcelStyle } from '../../../../assets/css/payroll-style';

// @services
import PayrollService from '../../../../services/newApi/PayrollService';

// @state
import { payrollContext } from '../../payrollView';
import { useUI } from '../../../../app/context/ui';

// @helpers
import AppHelper from '../../../../helpers/AppHelper';
import { downloadTemplate } from '../../../../helpers/FunctionsUtils';

const ExportToExcel = ({anchorEl, handleClose}) => {
  const style = MenuExcelStyle()
  const open = Boolean(anchorEl);
  const payrollService = new PayrollService();
  const { filterModal, currentTab } = useContext(payrollContext);
  const state = useSelector((state) => state);
  const organizationId = state?.organizationReducer?.organizationSelected?.id;
  const { blockUI, snackbarUI } = useUI();

  const downloadData = async (params) => {
    const {filter, detail} = params;

    const downloadOptions = {
      orgId: filterModal?.organization ?? organizationId,
      filters: filter ? filterModal : {}, 
      isMaxedOut: currentTab === 2 ? true : false, 
      fileName: detail ? 'payroll_employees_detail.csv' : currentTab === 2 ? 'payroll_employees_maxedOut.csv' : 'payroll_employees.csv', 
      isDetail: detail ? true : currentTab === 2 ? null : false,
    };
    handleDownload(downloadOptions);
  }

  const handleDownload = async (params) => {
    const {orgId, filters = {}, isMaxedOut = false, fileName, isDetail = false } = params;
    try {
      blockUI.current.open(true);
      const response = await payrollService.downloadFile(orgId, filters, isMaxedOut, isDetail);
      if(!response?.data)return emptyData();
      blockUI.current.open(false);
      handleClose();
      return downloadTemplate(response?.data, fileName);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }

  const emptyData = () => {
    AppHelper.showCustomMessage('No employees were found for the applied filters', snackbarUI);
    blockUI.current.open(false);
    handleClose();
  }
    
  return (
    <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{'aria-labelledby': 'basic-button'}}
        sx={style.containerMenu}
        transitionDuration={25}
        TransitionComponent={Fade}
        disableAutoFocusItem={true}
      >
        <MenuItem onClick={() => downloadData({filter:false, detail:false})} sx={style.menuItem}>Entire Data</MenuItem>
        <Divider/>
        <MenuItem onClick={() => downloadData({filter:true, detail:false})} sx={style.menuItem}>Filtered Data Only</MenuItem>
        <Divider/>
        <MenuItem onClick={() => downloadData({filter:false, detail:true})} sx={style.menuItem}>Employee Detail</MenuItem>
      </Menu>
  )
}

export default ExportToExcel