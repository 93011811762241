// @vendors
import React, { useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom';

// @components material
import {
  Button,
  EditIcon,
  Grid,
  TableCell,
  TableRow,
  Tooltip,
  ToggleOffIcon,
  ToggleOnIcon,
} from '../../../components/shared/MaterialUI'

// @assets
import { UserManagementStyle } from '../../../assets/css/employeeTable-style';
import { GeneralStyles } from '../../../assets/css/general-style';

// @helpers
import AppHelper from '../../../helpers/AppHelper';

// @context
import { useUI } from '../../../app/context/ui';
import { UserContext } from '../UserManagement';

// @services
import { ROUTENAME } from '../../../navigation/RouteName';
import UserServiceNewApi from '../../../services/newApi/UserServiceNewApi';

const UserRow = ({row}) => {
  const styles = useMemo(() => GeneralStyles(), []);
  const style = useMemo(() => UserManagementStyle(), []);
  const { blockUI, snackbarUI } = useUI();
  const userService = useMemo(() => new UserServiceNewApi(), []);
  const {getUsers} = useContext(UserContext);
  const history = useHistory();

  const activeOrInactivateUser = async () => {
    try {
      blockUI.current?.open(true);
      row?.isActive ? await userService.inActivateUser(row?.id) : await userService.activateUser(row?.id)
      getUsers()
      blockUI.current?.open(false);
    } catch (error) {
      blockUI.current?.open(false);
      AppHelper.checkError(error, snackbarUI);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell
          scope="row"
          sx={{...styles.tableCell, textAlign: 'left', paddingLeft: '30px'}}
        >
          {row?.fullName}
        </TableCell>

        <TableCell
          scope="row"
          sx={{
              ...styles.tableCell,
              ...styles.tableColumnHide,
              textAlign: 'left',
          }}
        >
          {row?.email}
        </TableCell>

        <TableCell
          scope="row"
          sx={{...styles.tableCell, textAlign: 'left'}}
        >
          {row?.role?.replace('_', ' ')}
        </TableCell>
        
        <TableCell
          scope="row"
          sx={{...styles.tableCell, textAlign: 'left'}}
        >
          {row?.isActive ? 'active' : 'inactive'}
        </TableCell>

          <TableCell
            sx={{
              ...styles.tableCell,
              ...styles.tableColumnHide
            }}
          >
            <Grid sx={style.containerButtons}>
              <Tooltip
                title={<p style={{ fontSize: "16px", margin: "0" }}>Edit user</p>}
                arrow
              >
                  <Button
                    variant="contained"
                    onClick={() => history.push(`${ROUTENAME.editUser}/${row?.id}`)}
                    disabled={!row?.isActive ? true : false}
                    sx={style.btnEdit}
                    children={
                      <EditIcon
                        disabled={!row?.isActive ? true : false}
                        sx={{fontSize: "2rem"}} 
                      />
                    }
                  />
              </Tooltip>

              <Tooltip
                title={
                  <p style={{ fontSize: "16px", margin: "0" }}>
                    {row?.isActive ? 'Inactive user' : 'Active user'}
                  </p>
                }
                arrow
              >
                  <Button
                    variant="contained"
                    onClick={() => activeOrInactivateUser()}
                    style={{ backgroundColor: "transparent", boxShadow: "none" }}
                    children={row?.isActive
                      ? <ToggleOnIcon sx={style.btnActive} />
                      : <ToggleOffIcon sx={style.btnActive} />
                    }
                  />
              </Tooltip>
            </Grid>
          </TableCell>      
      </TableRow>
    </>
  )
}

export default UserRow