/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import {
  Button,
  FormControl,
  Grid,
  OutlinedInput,
  Divider,
  FormHelperText,
  Typography,
  Select,
  MenuItem,
} from '@mui/material'
import { Box } from '@mui/system'
import { useFormik } from 'formik'
import * as Yup from 'yup'

// @assets
import { DialogFilterPayroll, EditPayrollStyle } from '../../../assets/css/payroll-style'

// @components
import ConfirmDialog from '../../Employer/components/helpers/DialogConfirm'

// @services
import PayrollService from '../../../services/newApi/PayrollService'

// @helpers
import AppHelper from '../../../helpers/AppHelper'
import { useUI } from '../../../app/context/ui'
import { payrollContext } from '../payrollView'
import { downloadTemplate, MenuItemProps } from '../../../helpers/FunctionsUtils'

const initialValues = {
    januaryHours: '',
    februaryHours: '',
    marchHours: '',
    aprilHours: '',
    mayHours: '',
    juneHours: '',
    julyHours: '',
    augustHours: '',
    septemberHours: '',
    octoberHours: '',
    novemberHours: '',
    decemberHours: '',
    januaryWage: '',
    februaryWage: '',
    marchWage: '',
    aprilWage: '',
    mayWage: '',
    juneWage: '',
    julyWage: '',
    augustWage: '',
    septemberWage: '',
    octoberWage: '',
    novemberWage: '',
    decemberWage: '',
    januaryCredits: '',
    februaryCredits: '',
    marchCredits: '',
    aprilCredits: '',
    mayCredits: '',
    juneCredits: '',
    julyCredits: '',
    augustCredits: '',
    septemberCredits: '',
    octoberCredits: '',
    novemberCredits: '',
    decemberCredits: '',
};

const listMonth = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

const FormEditHours = ({ handleClose, info }) => {
  const style = useMemo(() => EditPayrollStyle(), []);
  const styleButtons = useMemo(() => DialogFilterPayroll(), []);
  const payrollService = useMemo(() => new PayrollService(), []);
  const { blockUI, snackbarUI } = useUI();
  const {getRecordsList} = useContext(payrollContext);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const ONLYNUMBERS = 'Only numbers';
  const REGEXONLYNUMBER = /^[0-9]*\.?[0-9]*$/;
  const currentRef = useRef(null);
  const getYear = () => new Date().getFullYear();
  const [yearSelected, setYearSelected] = useState(getYear);

  const validationForm = Yup.object({
    januaryHours: Yup.string().notRequired().nullable().matches(REGEXONLYNUMBER, ONLYNUMBERS),
    februaryHours: Yup.string().notRequired().nullable().matches(REGEXONLYNUMBER, ONLYNUMBERS),
    marchHours: Yup.string().notRequired().nullable().matches(REGEXONLYNUMBER, ONLYNUMBERS),
    aprilHours: Yup.string().notRequired().nullable().matches(REGEXONLYNUMBER, ONLYNUMBERS),
    mayHours: Yup.string().notRequired().nullable().matches(REGEXONLYNUMBER, ONLYNUMBERS),
    juneHours: Yup.string().notRequired().nullable().matches(REGEXONLYNUMBER, ONLYNUMBERS),
    julyHours: Yup.string().notRequired().nullable().matches(REGEXONLYNUMBER, ONLYNUMBERS),
    augustHours: Yup.string().notRequired().nullable().matches(REGEXONLYNUMBER, ONLYNUMBERS),
    septemberHours: Yup.string().notRequired().nullable().matches(REGEXONLYNUMBER, ONLYNUMBERS),
    octoberHours: Yup.string().notRequired().nullable().matches(REGEXONLYNUMBER, ONLYNUMBERS),
    novemberHours: Yup.string().notRequired().nullable().matches(REGEXONLYNUMBER, ONLYNUMBERS),
    decemberHours: Yup.string().notRequired().nullable().matches(REGEXONLYNUMBER, ONLYNUMBERS),
    januaryWage: Yup.string().notRequired().nullable().matches(REGEXONLYNUMBER, ONLYNUMBERS),
    februaryWage: Yup.string().notRequired().nullable().matches(REGEXONLYNUMBER, ONLYNUMBERS),
    marchWage: Yup.string().notRequired().nullable().matches(REGEXONLYNUMBER, ONLYNUMBERS),
    aprilWage: Yup.string().notRequired().nullable().matches(REGEXONLYNUMBER, ONLYNUMBERS),
    mayWage: Yup.string().notRequired().nullable().matches(REGEXONLYNUMBER, ONLYNUMBERS),
    juneWage: Yup.string().notRequired().nullable().matches(REGEXONLYNUMBER, ONLYNUMBERS),
    julyWage: Yup.string().notRequired().nullable().matches(REGEXONLYNUMBER, ONLYNUMBERS),
    augustWage: Yup.string().notRequired().nullable().matches(REGEXONLYNUMBER, ONLYNUMBERS),
    septemberWage: Yup.string().notRequired().nullable().matches(REGEXONLYNUMBER, ONLYNUMBERS),
    octoberWage: Yup.string().notRequired().nullable().matches(REGEXONLYNUMBER, ONLYNUMBERS),
    novemberWage: Yup.string().notRequired().nullable().matches(REGEXONLYNUMBER, ONLYNUMBERS),
    decemberWage: Yup.string().notRequired().nullable().matches(REGEXONLYNUMBER, ONLYNUMBERS),
    januaryCredits: Yup.string().notRequired().nullable(),
    februaryCredits: Yup.string().notRequired().nullable(),
    marchCredits: Yup.string().notRequired().nullable(),
    aprilCredits: Yup.string().notRequired().nullable(),
    mayCredits: Yup.string().notRequired().nullable(),
    juneCredits: Yup.string().notRequired().nullable(),
    julyCredits: Yup.string().notRequired().nullable(),
    augustCredits: Yup.string().notRequired().nullable(),
    septemberCredits: Yup.string().notRequired().nullable(),
    octoberCredits: Yup.string().notRequired().nullable(),
    novemberCredits: Yup.string().notRequired().nullable(),
    decemberCredits: Yup.string().notRequired().nullable(),
  })

  const formik = useFormik({
      initialValues:initialValues,
      onSubmit:(values) => {
          submit(values)
      },
      validationSchema: validationForm
  })

  const submit = () =>{
    setOpenModalConfirm(true);
  }

  const getEmployee = async (year) => {
    currentRef.current = true;
    const yearSelected = year ? year : getYear();
    setYearSelected(yearSelected);
    formik.resetForm();
    try {
      blockUI.current.open(true);
      const response = await payrollService.getYtdByEmployees(info?.employeeId, yearSelected);
      preloadFields(response?.data?.data);
      blockUI.current.open(false);
    } catch (error) {
      blockUI.current.open(false);
      AppHelper.checkError(error, snackbarUI);
    }
  }

  useEffect(() => {
    if(!currentRef.current)getEmployee();
  }, [])

  const monthFieldMap = {
    1: { hours: 'januaryHours', wage: 'januaryWage', credits: 'januaryCredits' },
    2: { hours: 'februaryHours', wage: 'februaryWage', credits: 'februaryCredits' },
    3: { hours: 'marchHours', wage: 'marchWage', credits: 'marchCredits' },
    4: { hours: 'aprilHours', wage: 'aprilWage', credits: 'aprilCredits' },
    5: { hours: 'mayHours', wage: 'mayWage', credits: 'mayCredits' },
    6: { hours: 'juneHours', wage: 'juneWage', credits: 'juneCredits' },
    7: { hours: 'julyHours', wage: 'julyWage', credits: 'julyCredits' },
    8: { hours: 'augustHours', wage: 'augustWage', credits: 'augustCredits' },
    9: { hours: 'septemberHours', wage: 'septemberWage', credits: 'septemberCredits' },
    10: { hours: 'octoberHours', wage: 'octoberWage', credits: 'octoberCredits' },
    11: { hours: 'novemberHours', wage: 'novemberWage', credits: 'novemberCredits' },
    12: { hours: 'decemberHours', wage: 'decemberWage', credits: 'decemberCredits' },
  };

  const preloadFields = (data) => {
    if (!Array.isArray(data) || !data.length) return;
    data?.forEach((item) => {
      const {month, currentMonthHours, currentMonthGrossWage, currentMonthTaxCredit} = item || {}; 
      const fields = monthFieldMap[month];
      if(fields){
        formik.setFieldValue(fields.hours, JSON.stringify(currentMonthHours) || 0);
        formik.setFieldValue(fields.wage, JSON.stringify(currentMonthGrossWage) || 0);
        formik.setFieldValue(fields.credits, JSON.stringify(currentMonthTaxCredit) || 0);
      }
    });
  }
  
  const handleCloseModalConfirm = (value) => {
    if(value === 'yes')handleSubmitData();
    setOpenModalConfirm(false);
  } 

  const months = [
    { year: yearSelected, month: 1, hoursKey: "januaryHours", wageKey: "januaryWage" },
    { year: yearSelected, month: 2, hoursKey: "februaryHours", wageKey: "februaryWage" },
    { year: yearSelected, month: 3, hoursKey: "marchHours", wageKey: "marchWage" },
    { year: yearSelected, month: 4, hoursKey: "aprilHours", wageKey: "aprilWage" },
    { year: yearSelected, month: 5, hoursKey: "mayHours", wageKey: "mayWage" },
    { year: yearSelected, month: 6, hoursKey: "juneHours", wageKey: "juneWage" },
    { year: yearSelected, month: 7, hoursKey: "julyHours", wageKey: "julyWage" },
    { year: yearSelected, month: 8, hoursKey: "augustHours", wageKey: "augustWage" },
    { year: yearSelected, month: 9, hoursKey: "septemberHours", wageKey: "septemberWage" },
    { year: yearSelected, month: 10, hoursKey: "octoberHours", wageKey: "octoberWage" },
    { year: yearSelected, month: 11, hoursKey: "novemberHours", wageKey: "novemberWage" },
    { year: yearSelected, month: 12, hoursKey: "decemberHours", wageKey: "decemberWage" },
  ];
  
  const handleSubmitData = async () => {
    const values = months.map(({ year, month, hoursKey, wageKey }) => ({
      year,
      month,
      monthHours: JSON.parse(formik.values[hoursKey] || 0),
      monthWage: JSON.parse(formik.values[wageKey] || 0),
    }));
    return updateData(values);
  }

  const updateData = async (values) => {
    try {
      blockUI.current.open(true);
      await payrollService.updateHourAndWage(info?.employeeId, values);
      getRecordsList();
      setYearSelected('');
      handleClose();
    } catch (error) {
      blockUI.current.open(false);
      AppHelper.checkError(error, snackbarUI); 
    }
  }

  const downloadData = async () => {
    try {
      blockUI.current.open(true);
      const response = await payrollService.downloadFileByDetail(info?.employeeId);
      blockUI.current.open(false);
      return downloadTemplate(response?.data, `payroll_employee_detail_(${info?.employeeFullName}).csv`);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }

  const handleCloseEdit = () => {
    handleClose();
    setYearSelected(getYear());
  }

  const getRangeYear = () => {
    const startYear = getYear() - 20;
    const endYear = getYear();
    let yearRange = [];
    for (let i = startYear; i <= endYear; i++) {
        yearRange.push(i);
    }
    return yearRange.reverse();
  }

  return (
    <>
      <Box sx={style.formsHoursAndWage}>
        <Box sx={style.containerSelect}>
            <Typography sx={style.label}>Select year</Typography>
            <FormControl>
                <Select
                    value={yearSelected}
                    id='selectYear'
                    onChange={(e)=> {getEmployee(e.target.value)}}
                    size="small"
                    variant="outlined"
                    sx={style.fieldsInput}
                    MenuProps={MenuItemProps}
                    margin="dense" 
                >
                    {getRangeYear()?.map((item, index) => (
                        <MenuItem value={item} key={index}>
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>

        <Box sx={{...style.containerNameMonth}}>
            <Grid sx={{width: '90px'}}></Grid>
            <Grid container sx={style.nameMonth} >
                {listMonth?.map((month, index) => (
                    <Grid item xs={1} md={1} lg={1} sx={style.spacingItemForm} key={index}>
                        <Typography sx={style.month}>{month}</Typography>
                    </Grid>
                ))}
            </Grid>
        </Box>
        <Divider sx={style.divider}/>

        <Box sx={{...style.containerForms, marginTop: {xs: '20px', md: '0px'}}}>
          <form onSubmit={formik.handleSubmit}>
            <Grid sx={{display: {xs: 'block', md: 'flex'}}}>
              <Box sx={style.hourWage}>Hours</Box>

              <Grid container sx={style.containerFormHours}>
                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Jan</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="januaryHours" 
                        variant="outlined"
                        name='januaryHours'
                        value={formik.values.januaryHours}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.januaryHours && Boolean(formik.errors.januaryHours)}
                      />
                    </FormControl>
                    <FormHelperText style={{color: '#f44336'}}>
                      {formik.errors.januaryHours}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Feb</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="februaryHours" 
                        variant="outlined"
                        name='februaryHours'
                        value={formik.values.februaryHours}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.februaryHours && Boolean(formik.errors.februaryHours)}
                      />
                    </FormControl>
                    <FormHelperText style={{color: '#f44336'}}>
                      {formik.errors.februaryHours}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Mar</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="marchHours" 
                        variant="outlined"
                        name='marchHours'
                        value={formik.values.marchHours}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.marchHours && Boolean(formik.errors.marchHours)}
                      />
                    </FormControl>
                    <FormHelperText style={{color: '#f44336'}}>
                      {formik.errors.marchHours}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Apr</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="aprilHours" 
                        variant="outlined"
                        name='aprilHours'
                        value={formik.values.aprilHours}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.aprilHours && Boolean(formik.errors.aprilHours)}
                      />
                    </FormControl>
                    <FormHelperText style={{color: '#f44336'}}>
                      {formik.errors.aprilHours}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>May</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="mayHours" 
                        variant="outlined"
                        name='mayHours'
                        value={formik.values.mayHours}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.mayHours && Boolean(formik.errors.mayHours)}
                      />
                    </FormControl>
                    <FormHelperText style={{color: '#f44336'}}>
                      {formik.errors.mayHours}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Jun</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="juneHours" 
                        variant="outlined"
                        name='juneHours'
                        value={formik.values.juneHours}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.juneHours && Boolean(formik.errors.juneHours)}
                      />
                    </FormControl>
                    <FormHelperText style={{color: '#f44336'}}>
                      {formik.errors.juneHours}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Jul</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="julyHours" 
                        variant="outlined"
                        name='julyHours'
                        value={formik.values.julyHours}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.julyHours && Boolean(formik.errors.julyHours)}
                      />
                    </FormControl>
                    <FormHelperText style={{color: '#f44336'}}>
                      {formik.errors.julyHours}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Aug</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="augustHours" 
                        variant="outlined"
                        name='augustHours'
                        value={formik.values.augustHours}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.augustHours && Boolean(formik.errors.augustHours)}
                      />
                    </FormControl>
                    <FormHelperText style={{color: '#f44336'}}>
                      {formik.errors.augustHours}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Sep</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="septemberHours" 
                        variant="outlined"
                        name='septemberHours'
                        value={formik.values.septemberHours}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.septemberHours && Boolean(formik.errors.septemberHours)}
                      />
                    </FormControl>
                    <FormHelperText style={{color: '#f44336'}}>
                      {formik.errors.septemberHours}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Oct</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="octoberHours" 
                        variant="outlined"
                        name='octoberHours'
                        value={formik.values.octoberHours}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.octoberHours && Boolean(formik.errors.octoberHours)}
                      />
                    </FormControl>
                    <FormHelperText style={{color: '#f44336'}}>
                      {formik.errors.octoberHours}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Nov</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="novemberHours" 
                        variant="outlined"
                        name='novemberHours'
                        value={formik.values.novemberHours}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.novemberHours && Boolean(formik.errors.novemberHours)}
                      />
                    </FormControl>
                    <FormHelperText style={{color: '#f44336'}}>
                      {formik.errors.novemberHours}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Dic</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="decemberHours" 
                        variant="outlined"
                        name='decemberHours'
                        value={formik.values.decemberHours}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.decemberHours && Boolean(formik.errors.decemberHours)}
                      />
                    </FormControl>
                    <FormHelperText style={{color: '#f44336'}}>
                      {formik.errors.decemberHours}
                    </FormHelperText>
                  </Grid>
              </Grid>
            </Grid>

            <Divider sx={{...style.divider, margin: '15px 0px'}}/>

            <Grid sx={{display: {xs: 'block', md: 'flex'}}}>
              <Box sx={style.hourWage}>Wage ($)</Box>

              <Grid container sx={style.containerFormHours}>
                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Jan</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="januaryWage" 
                        variant="outlined"
                        name='januaryWage'
                        value={formik.values.januaryWage}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.januaryWage && Boolean(formik.errors.januaryWage)}
                      />
                    </FormControl>
                    <FormHelperText style={{color: '#f44336'}}>
                      {formik.errors.januaryWage}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Feb</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="februaryWage" 
                        variant="outlined"
                        name='februaryWage'
                        value={formik.values.februaryWage}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.februaryWage && Boolean(formik.errors.februaryWage)}
                      />
                    </FormControl>
                    <FormHelperText style={{color: '#f44336'}}>
                      {formik.errors.februaryWage}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Mar</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="marchWage" 
                        variant="outlined"
                        name='marchWage'
                        value={formik.values.marchWage}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.marchWage && Boolean(formik.errors.marchWage)}
                      />
                    </FormControl>
                    <FormHelperText style={{color: '#f44336'}}>
                      {formik.errors.marchWage}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Apr</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="aprilWage" 
                        variant="outlined"
                        name='aprilWage'
                        value={formik.values.aprilWage}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.aprilWage && Boolean(formik.errors.aprilWage)}
                      />
                    </FormControl>
                    <FormHelperText style={{color: '#f44336'}}>
                      {formik.errors.aprilWage}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>May</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="mayWage" 
                        variant="outlined"
                        name='mayWage'
                        value={formik.values.mayWage}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.mayWage && Boolean(formik.errors.mayWage)}
                      />
                    </FormControl>
                    <FormHelperText style={{color: '#f44336'}}>
                      {formik.errors.mayWage}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Jun</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="juneWage" 
                        variant="outlined"
                        name='juneWage'
                        value={formik.values.juneWage}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.juneWage && Boolean(formik.errors.juneWage)}
                      />
                    </FormControl>
                    <FormHelperText style={{color: '#f44336'}}>
                      {formik.errors.juneWage}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Jul</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="julyWage" 
                        variant="outlined"
                        name='julyWage'
                        value={formik.values.julyWage}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.julyWage && Boolean(formik.errors.julyWage)}
                      />
                    </FormControl>
                    <FormHelperText style={{color: '#f44336'}}>
                      {formik.errors.julyWage}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Aug</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="augustWage" 
                        variant="outlined"
                        name='augustWage'
                        value={formik.values.augustWage}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.augustWage && Boolean(formik.errors.augustWage)}
                      />
                    </FormControl>
                    <FormHelperText style={{color: '#f44336'}}>
                      {formik.errors.augustWage}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Sep</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="septemberWage" 
                        variant="outlined"
                        name='septemberWage'
                        value={formik.values.septemberWage}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.septemberWage && Boolean(formik.errors.septemberWage)}
                      />
                    </FormControl>
                    <FormHelperText style={{color: '#f44336'}}>
                      {formik.errors.septemberWage}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Oct</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="octoberWage" 
                        variant="outlined"
                        name='octoberWage'
                        value={formik.values.octoberWage}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.octoberWage && Boolean(formik.errors.octoberWage)}
                      />
                    </FormControl>
                    <FormHelperText style={{color: '#f44336'}}>
                      {formik.errors.octoberWage}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Nov</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="novemberWage" 
                        variant="outlined"
                        name='novemberWage'
                        value={formik.values.novemberWage}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.novemberWage && Boolean(formik.errors.novemberWage)}
                      />
                    </FormControl>
                    <FormHelperText style={{color: '#f44336'}}>
                      {formik.errors.novemberWage}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Dic</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="decemberWage" 
                        variant="outlined"
                        name='decemberWage'
                        value={formik.values.decemberWage}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.decemberWage && Boolean(formik.errors.decemberWage)}
                      />
                    </FormControl>
                    <FormHelperText style={{color: '#f44336'}}>
                      {formik.errors.decemberWage}
                    </FormHelperText>
                  </Grid>
              </Grid>
            </Grid>
            
            <Divider sx={{...style.divider, margin: '15px 0px'}}/>

            <Grid sx={{display: {xs: 'block', md: 'flex'}}}>
              <Box sx={style.hourWage}>Credits</Box>

              <Grid container sx={style.containerFormHours}>
                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Jan</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="januaryCredits" 
                        variant="outlined"
                        name='januaryCredits'
                        value={formik.values.januaryCredits}
                        disabled={true}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.januaryCredits && Boolean(formik.errors.januaryCredits)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Feb</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="februaryCredits" 
                        variant="outlined"
                        name='februaryCredits'
                        value={formik.values.februaryCredits}
                        disabled={true}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.februaryCredits && Boolean(formik.errors.februaryCredits)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Mar</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="marchCredits" 
                        variant="outlined"
                        name='marchCredits'
                        value={formik.values.marchCredits}
                        size="small"
                        disabled={true}
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.marchCredits && Boolean(formik.errors.marchCredits)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Apr</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="aprilCredits" 
                        variant="outlined"
                        name='aprilCredits'
                        disabled={true}
                        value={formik.values.aprilCredits}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.aprilCredits && Boolean(formik.errors.aprilCredits)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>May</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="mayCredits" 
                        variant="outlined"
                        name='mayCredits'
                        value={formik.values.mayCredits}
                        size="small"
                        disabled={true}
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.mayCredits && Boolean(formik.errors.mayCredits)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Jun</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="juneCredits" 
                        variant="outlined"
                        name='juneCredits'
                        value={formik.values.juneCredits}
                        size="small"
                        disabled={true}
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.juneCredits && Boolean(formik.errors.juneCredits)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Jul</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="julyCredits" 
                        variant="outlined"
                        name='julyCredits'
                        disabled={true}
                        value={formik.values.julyCredits}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.julyCredits && Boolean(formik.errors.julyCredits)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Aug</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="augustCredits" 
                        variant="outlined"
                        name='augustCredits'
                        disabled={true}
                        value={formik.values.augustCredits}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.augustCredits && Boolean(formik.errors.augustCredits)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Sep</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="septemberCredits" 
                        variant="outlined"
                        name='septemberCredits'
                        value={formik.values.septemberCredits}
                        disabled={true}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.septemberCredits && Boolean(formik.errors.septemberCredits)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Oct</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="octoberCredits" 
                        variant="outlined"
                        name='octoberCredits'
                        value={formik.values.octoberCredits}
                        disabled={true}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.octoberCredits && Boolean(formik.errors.octoberCredits)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Nov</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="novemberCredits" 
                        variant="outlined"
                        name='novemberCredits'
                        disabled={true}
                        value={formik.values.novemberCredits}
                        size="small"
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.novemberCredits && Boolean(formik.errors.novemberCredits)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={4} md={1} lg={1} sx={style.spacingItemForm}>
                    <Typography sx={style.monthSx}>Dic</Typography>
                    <FormControl fullWidth>
                      <OutlinedInput
                        id="decemberCredits" 
                        variant="outlined"
                        name='decemberCredits'
                        value={formik.values.decemberCredits}
                        size="small"
                        disabled={true}
                        sx={style.fieldsInput}
                        onChange={formik.handleChange}
                        error={formik.touched.decemberCredits && Boolean(formik.errors.decemberCredits)}
                      />
                    </FormControl>
                  </Grid>
              </Grid>
            </Grid>

            <Divider sx={{...style.divider}}/>

            <Box sx={styleButtons.divActions}>
                <Button
                    onClick={handleCloseEdit}
                    variant="contained"
                    sx={styleButtons.buttonCancel}
                >
                    Cancel
                </Button>
                <Button
                    onClick={downloadData}
                    variant="contained"
                    sx={styleButtons.buttonApply}
                >
                    Export to excel
                </Button>
                <Button
                    type='submit'
                    variant="contained"
                    sx={styleButtons.buttonApply}
                >
                    Apply
                </Button>
            </Box>
          </form>
          <ConfirmDialog
              id="confirm-dialog"
              keepMounted
              open={openModalConfirm}
              onClose={handleCloseModalConfirm}
              title='Are you sure to apply these changes?'
          />
        </Box>
      </Box>
    
    </>
  )
}

export default FormEditHours